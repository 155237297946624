import { useMemo } from "react";
import { startsWithAllWords } from "@remhealth/ui";
import { Practice, PracticeFilterSet, PracticeStatus, Product } from "@remhealth/apollo";
import { useStore } from "./useStore";

export interface PracticeFilterOptions {
  status?: PracticeStatus[];
  bellsOnly?: boolean;
  hasPullIssues?: boolean;
  databaseExists?: boolean;
  product?: ProductChoice[];
  query?: string;
}

export type ProductChoice = Product | "Unknown";

export function usePracticesView(options: PracticeFilterOptions) {
  const store = useStore();

  return useMemo(() => {
    return store.practices.view({
      filters: {
        online: createPracticeFilters(options),
        offline: s => offlineFilter(s, options),
      },
    });
  }, [JSON.stringify(options)]);
}

export function createPracticeFilters(options: PracticeFilterOptions): PracticeFilterSet[] {
  const bellsFilter: PracticeFilterSet = !options.bellsOnly ? {} : {
    features: {
      matches: "Bells",
      presence: "MustBePresent",
    },
  };

  const statusFilter: PracticeFilterSet = !options.status ? {} : {
    status: { in: options.status },
  };

  const syncIssueFilter: PracticeFilterSet = !options.hasPullIssues ? {} : {
    pullOutcome: {
      in: ["Failed", "Warning"],
      presence: "MustBePresent",
    },
  };

  const databaseNotExistsFilter: PracticeFilterSet = options.databaseExists === undefined ? {} : {
    databaseExists: options.databaseExists,
  };

  const productFilters: PracticeFilterSet[] = [];

  if (options.product?.includes("Unknown")) {
    productFilters.push({
      product: { presence: "NotPresent" },
    });
  }

  const actualProductFilters = options.product ? options.product.filter(isProduct) : [];
  if (actualProductFilters.length > 0) {
    productFilters.push({
      product: { in: actualProductFilters },
    });
  }

  const queryFilters: PracticeFilterSet[] = !options.query ? [] : [
    {
      display: { startsWithAllWords: options.query },
    },
    {
      networkId: { startsWith: options.query },
    },
  ];

  let filters: PracticeFilterSet[] = [{
    ...bellsFilter,
    ...statusFilter,
    ...syncIssueFilter,
    ...databaseNotExistsFilter,
  }];

  if (productFilters.length > 0) {
    filters = productFilters.flatMap(productFilter => filters.length === 0 ? [productFilter] : filters.map(filter => ({
      ...filter,
      ...productFilter,
    })));
  }

  if (queryFilters.length > 0) {
    filters = queryFilters.flatMap(queryFilter => filters.length === 0 ? [queryFilter] : filters.map(filter => ({
      ...filter,
      ...queryFilter,
    })));
  }

  return filters;
}

function offlineFilter(practice: Practice, options: PracticeFilterOptions) {
  if (options.bellsOnly && !practice.features.includes("Bells")) {
    return false;
  }

  if (options.status && !options.status.includes(practice.status)) {
    return false;
  }

  const pullOutcome = practice.meta?.pullResult?.outcome;
  if (options.hasPullIssues && pullOutcome !== "Failed" && pullOutcome !== "Warning") {
    return false;
  }

  if (options.query && !startsWithAllWords(practice.name, options.query) && !practice.networkId.startsWith(options.query)) {
    return false;
  }

  if (options.product && options.product.length > 0) {
    if (!practice.product && !options.product.includes("Unknown")) {
      return false;
    }

    if (practice.product && !options.product.includes(practice.product)) {
      return false;
    }
  }

  if (options.databaseExists !== undefined && practice.databaseExists !== options.databaseExists) {
    return false;
  }

  return true;
}

function isProduct(choice: ProductChoice): choice is Product {
  return choice !== "Unknown";
}
