import { useEffect, useState } from "react";
import { ContactPoint, Practitioner } from "@remhealth/apollo";
import { Button, Classes, Dialog, FormGroup, HTMLTable, ProgressBar, useAbort } from "@remhealth/ui";
import { useApollo } from "@remhealth/host";
import { Text } from "~/text";
import { useErrorHandler } from "~/app";
import { useStore } from "../stores";
import { Csv } from "../utils";
import { Failures, ProgressContainer, ProgresssInfo, UsersTextArea } from "./importEhrUserDialog.styles";

export interface BulkUpdateUserEmailsProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ImportFailure {
  profile: string;
  email: string;
  reason: string;
}

interface ImportRow {
  profile: string;
  email: string;
}

const placeholder = "profile, email\n01H28XNA13Y946QJTHP9Y4QYDX, user@email.com\n01H41BSJDQQ1KGPCQ19CT8HB1N, another@email.com\n... and so on ...";

export const BulkUpdateUserEmails = (props: BulkUpdateUserEmailsProps) => {
  const { isOpen, onClose } = props;

  const apollo = useApollo();
  const store = useStore();
  const abort = useAbort();
  const handleError = useErrorHandler();

  const [data, setData] = useState("");
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState<number>();
  const [failures, setFailures] = useState<ImportFailure[]>([]);

  useEffect(() => {
    if (isOpen) {
      abort.reset();

      setData("");
      setTotal(undefined);
      setCurrent(0);
      setFailures([]);
    }
  }, [isOpen]);

  const importing = total !== undefined;
  const doneImporting = current === total;
  const successfulCount = total !== undefined ? total - failures.length : 0;

  return (
    <Dialog
      autoFocus
      isOpen={isOpen}
      title="Bulk Update User Emails"
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        {importing
          ? (
            <>
              <ProgressContainer>
                <ProgressBar intent="success" stripes={!doneImporting} value={current / total} />
                <ProgresssInfo>
                  {doneImporting
                    ? <>Imported <strong>{successfulCount}</strong> user{successfulCount !== 1 ? "s" : ""}.</>
                    : <>Importing <strong>{current}</strong> of <strong>{total}</strong>...</>}
                </ProgresssInfo>
              </ProgressContainer>
              {failures && failures.length > 0 && (
                <>
                  <h4>{failures.length} import failure{failures.length !== 1 ? "s" : ""}</h4>
                  <Failures>
                    <HTMLTable compact stickyHeader>
                      <thead>
                        <tr>
                          <th>Profile</th>
                          <th>Email</th>
                          <th>Failure reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {failures.map((failure, idx) => (
                          <tr key={idx}>
                            <td>{failure.profile}</td>
                            <td>{failure.email}</td>
                            <td>{failure.reason}</td>
                          </tr>
                        ))}
                      </tbody>
                    </HTMLTable>
                  </Failures>
                </>
              )}
            </>
          )
          : (
            <>
              <FormGroup label="Enter profile ID and emails:" subLabel="One profile and email per line separated by a comma.">
                <UsersTextArea fill large placeholder={placeholder} onChange={setData} />
              </FormGroup>
            </>
          )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {importing && current !== total && <Button intent="danger" label="Abort" onClick={handleClose} />}
          {importing && current === total && <Button minimal intent="primary" label="Close" onClick={handleClose} />}
          {!importing && <Button minimal intent="primary" label={Text.Cancel} onClick={handleClose} />}
          {!importing && <Button disabled={!data} intent="primary" label="Import" onClick={handleImport} />}
        </div>
      </div>
    </Dialog>
  );

  function handleClose() {
    abort.reset();
    onClose();
  }

  async function handleImport() {
    const signal = abort.signal;

    const parser = Csv<ImportRow>();

    try {
      const results = parser.cast(data);

      setCurrent(0);
      setTotal(results.length);

      for (const row of results) {
        if (signal.aborted) {
          break;
        }

        // User, Supervisor, StaffId, Email
        try {
          const user = await findUser(row.profile, signal);

          if (!user) {
            setFailures(f => [...f, {
              profile: row.profile,
              email: row.email,
              reason: "User not found",
            }]);
            continue;
          }

          await updateEmails(user, row.email);

          setCurrent(c => c + 1);
        } catch (error) {
          handleError(error);
        }
      }
    } catch (error) {
      setFailures(f => [...f, { profile: "", email: "", reason: "Failed parsing CSV" }]);
      handleError(error);
    }
  }

  async function findUser(id: string, abort: AbortSignal) {
    return await apollo.practitioners.fetchById(id, { abort });
  }

  async function updateEmails(user: Practitioner, email: string) {
    const newEmail: ContactPoint = {
      system: "Email",
      use: "Work",
      value: email,
      preferred: true,
    };

    let nonPrimary: ContactPoint[] = [];
    if (user.telecoms.some(t => t.value === email)) {
      // Reorder the telecomes to ensure the work email is first
      for (const tele of user.telecoms) {
        if (tele.value !== email) {
          nonPrimary.push(tele);
        }
      }
    } else {
      nonPrimary = user.telecoms;
    }

    user.telecoms = [newEmail, ...nonPrimary];
    await store.practitioners.upsertAsync(user);
  }
};
