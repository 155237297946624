import { type SetStateAction, useLayoutEffect, useMemo } from "react";
import { type NavigateOptions, type URLSearchParamsInit, useLocation, useSearchParams } from "react-router-dom";
import type { StatefulRefValue } from "@remhealth/ui";

let pendingParams: URLSearchParams | undefined;

export interface StatefulSearchParamsRef extends StatefulRefValue<URLSearchParams> {
  set(value: SetStateAction<URLSearchParams>, navigateOpts?: NavigateOptions): void;
}

/**
 * Fixes issue with useSearchParams's state setter not reflecting pending changes between renders
 * https://github.com/remix-run/react-router/issues/9304
 */
export function useSearchParamsRef(defaultInit?: URLSearchParamsInit): StatefulSearchParamsRef {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(defaultInit);

  useLayoutEffect(() => {
    pendingParams = undefined;
  }, [searchParams]);

  return useMemo(() => ({
    get current(): URLSearchParams {
      return pendingParams ?? searchParams;
    },
    set,
  }), [searchParams, location.pathname]);

  function set(nextValue: SetStateAction<URLSearchParams>, navigateOpts?: NavigateOptions): void {
    const prevValue = pendingParams ?? searchParams;
    const value = typeof nextValue === "function" ? nextValue(prevValue) : nextValue;
    pendingParams = new URLSearchParams(value);

    setSearchParams(value, navigateOpts);
  }
}
