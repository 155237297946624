import { useRef, useState } from "react";
import { ulid } from "ulid";
import { BeforeLeavePrompt, Form, FormActions, FormContent } from "@remhealth/core";
import { Alert, Button, Classes } from "@remhealth/ui";
import { NounRule, SpeechRuleCategory, SpeechRuleTemplate, VerbRule, isNotNew, makeNew } from "@remhealth/apollo";
import { useRegistry } from "~/contexts";
import { Text } from "~/text";
import {
  AcronymRuleForm,
  LiteralRuleForm,
  LonelyNounRuleForm,
  NounRuleForm,
  StaffVerbRuleForm
} from "./rules";

import { SpeechRuleTemplateForm, isRuleForm, speechRuleTemplateSchema } from "./schema";
import { DialogWindow } from "./editRuleDialog.styles";

export interface EditRuleDialogProps {
  rule?: SpeechRuleTemplateForm;
  onCopy: (rule: SpeechRuleTemplateForm) => void;
  onClose?: () => void;
}

const voidRule: SpeechRuleTemplateForm = {
  category: SpeechRuleCategory.AnyService,
  rule: { type: "PatientAssessment" },
};

type SpeechRuleTemplateType = SpeechRuleTemplate["rule"]["type"];
const verbRules = new Set<SpeechRuleTemplateType>(["StaffVerb", "StaffVerbObject", "StaffVerbPatient", "StaffVerbRepeated"]);
const nounRules = new Set<SpeechRuleTemplateType>(["LonelyNoun", "Noun"]);

export const EditRuleDialog = (props: EditRuleDialogProps) => {
  const { rule, onClose, onCopy } = props;

  const registry = useRegistry();
  const strictValidation = useRef(false);

  const isOpen = !!rule;

  return (
    <Form<SpeechRuleTemplateForm>
      initialValues={rule ?? voidRule}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {form => (
        <EditRuleDialogContent
          form={form}
          isOpen={isOpen}
          onClose={() => handleClose(form)}
          onCopy={() => onCopy(form.values)}
          onDelete={() => handleDelete(form)}
          onStrictValidation={handleStrictValidation}
        />
      )}
    </Form>
  );

  function validationSchema() {
    return speechRuleTemplateSchema(strictValidation.current);
  }

  function handleStrictValidation() {
    strictValidation.current = true;
  }

  function handleClose(form: FormActions<SpeechRuleTemplateForm>) {
    onClose?.();
    strictValidation.current = false;
    form.resetForm();
  }

  async function handleSave(values: SpeechRuleTemplateForm, form: FormActions<SpeechRuleTemplateForm>) {
    // Create a new rule if the type changed
    if (rule?.id && isNotNew(values) && shouldDeleteExistingRule(values)) {
      await registry.speechRuleTemplates.deleteById(values.id);
      values = makeNew(values);
    }

    await registry.speechRuleTemplates.update({
      resourceType: "SpeechRuleTemplate",
      ...values,
      id: values.id ?? ulid(),
    });
    handleClose(form);
  }

  async function handleDelete(form: FormActions<SpeechRuleTemplateForm>) {
    if (rule?.id) {
      await registry.speechRuleTemplates.deleteById(rule.id);
    }

    handleClose(form);
  }

  function shouldDeleteExistingRule(values: SpeechRuleTemplateForm) {
    // No existing rule to delete
    if (!rule) {
      return false;
    }

    // If the rule's type changed, must delete it
    if (values.rule.type !== rule.rule.type) {
      return true;
    }

    // If rule's main word (verb, acronym, noun, etc) changed, then consider this to be a new rule
    if (rule.rule.type === "Acronym" && values.rule.type === "Acronym") {
      return values.rule.acronym.localeCompare(rule.rule.acronym) !== 0;
    }

    if (rule.rule.type === "Literal" && values.rule.type === "Literal") {
      return values.rule.text.localeCompare(rule.rule.text) !== 0;
    }

    if (nounRules.has(rule.rule.type)) {
      const valueNoun = values.rule as NounRule;
      const ruleNoun = rule.rule as NounRule;

      return valueNoun.noun.localeCompare(ruleNoun.noun) !== 0;
    }

    if (verbRules.has(rule.rule.type)) {
      const valueVerb = values.rule as VerbRule;
      const ruleVerb = rule.rule as VerbRule;

      return valueVerb.verb.localeCompare(ruleVerb.verb) !== 0;
    }

    return false;
  }
};

interface EditRuleDialogContentProps {
  isOpen: boolean;
  form: FormContent<SpeechRuleTemplateForm>;
  onStrictValidation: () => void;
  onDelete: () => void;
  onClose: () => void;
  onCopy: () => void;
}

function EditRuleDialogContent(props: EditRuleDialogContentProps) {
  const { isOpen, form, onStrictValidation, onClose, onCopy, onDelete } = props;

  const [promptClose, setPromptClose] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const small = isRuleForm(form.fields, "PatientAssessment", "PatientNoun", "MissingActor", "NonThirdPerson");
  const title = small ? "Configure" : form.fields.id ? "Edit Rule" : "Create Rule";
  const showButton = !small && form.fields.id;

  return (
    <>
      <DialogWindow $small={small} isOpen={isOpen} title={title} onClose={handleClose}>
        <div className={Classes.DIALOG_BODY}>
          {getRuleForm(form)}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {showButton && <Button minimal intent="primary" label={Text.Copy} onClick={onCopy} />}
            {showButton && <Button minimal className="left" intent="danger" label="Delete" onClick={handleDeleteClick} />}
            <Button minimal intent="primary" label={Text.Cancel} onClick={confirmClose} />
            <Button intent="primary" label={Text.Save(Text.ClinicalRecommendation)} onClick={handleSaveClick} />
          </div>
        </div>
      </DialogWindow>
      <BeforeLeavePrompt
        isOpen={isOpen && promptClose}
        when={form.dirty && !form.isSubmitting}
        onCancelLeave={handleCloseConfirmation}
        onConfirmLeave={handleClose}
      />
      <Alert
        canEscapeKeyCancel
        cancelButtonText={Text.No}
        confirmButtonText={Text.ConfirmDelete}
        intent="danger"
        isOpen={confirmDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      >
        <p>{Text.DeleteConfirmation(Text.ClinicalRecommendation)}</p>
      </Alert>
    </>
  );

  async function handleSaveClick() {
    onStrictValidation();
    await form.submitForm();
  }

  function handleClose() {
    if (form.dirty && !promptClose) {
      setPromptClose(true);
    } else {
      confirmClose();
    }
  }

  function confirmClose() {
    setPromptClose(false);
    setConfirmDelete(false);
    handleCloseConfirmation();
    onClose?.();
    form.resetForm();
  }

  function handleCloseConfirmation() {
    setPromptClose(false);
  }

  function handleDeleteClick() {
    setConfirmDelete(true);
  }

  function handleCancelDelete() {
    setConfirmDelete(false);
  }

  function handleConfirmDelete() {
    setConfirmDelete(false);
    onDelete();
  }

  function getRuleForm(form: FormContent<SpeechRuleTemplateForm>): JSX.Element {
    const ruleForm = form.fields;

    if (isRuleForm(ruleForm, "Acronym")) {
      return <AcronymRuleForm form={ruleForm} />;
    } else if (isRuleForm(ruleForm, "StaffVerb", "StaffVerbPatient", "StaffVerbObject", "StaffVerbRepeated")) {
      return <StaffVerbRuleForm form={ruleForm} />;
    } else if (isRuleForm(ruleForm, "Noun")) {
      return <NounRuleForm form={ruleForm} />;
    } else if (isRuleForm(ruleForm, "LonelyNoun")) {
      return <LonelyNounRuleForm form={ruleForm} />;
    } else if (isRuleForm(ruleForm, "Literal")) {
      return <LiteralRuleForm form={ruleForm} />;
    }

    return <></>;
  }
}
