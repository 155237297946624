import { type Dispatch, type SetStateAction } from "react";
import { useSearchParamValue } from "./useSearchParamValue";

export function useSearchParamBoolean(name: string, trueValue = "true"): [value: boolean, setValue: Dispatch<SetStateAction<boolean>>] {
  const [stringValue, setStringValue] = useSearchParamValue(name);

  return [stringValue === trueValue, handleSetValue];

  function handleSetValue(desiredValue: SetStateAction<boolean>) {
    setStringValue(desiredValue ? trueValue : "");
  }
}
