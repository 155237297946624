import {
  type CheckResult,
  type Checkable,
  type GenAiEditAction,
  type GenAiModel,
  type InflectionsResponse,
  type LanguageCheckOptions,
  type LanguageChecker,
  type LanguageCheckerFactoryOptions,
  MetisCheckClient,
  type MetisClientConfig,
  MetisGenAiClient,
  MetisNlpClient,
  MetisTranslateClient,
  MetisWordClient,
  type PredictResponse,
  type ProfileWord,
  type RequestHandler,
  type TranslateLanguage,
  type Zone
} from "@remhealth/metis";
import { sanitizeBaseUrl } from "@remhealth/ui";
import { AccessToken } from "@remhealth/host";

export interface GlobalLanguageServiceConfig extends MetisClientConfig {
  application: string;
  zone: Zone;
  accessToken: Readonly<AccessToken>;
  headers?: Record<string, string>;
}

export class GlobalLanguageService {
  protected readonly checkClient: MetisCheckClient;
  protected readonly nlpClient: MetisNlpClient;
  protected readonly translateClient: MetisTranslateClient;
  protected readonly genAiClient: MetisGenAiClient;
  protected readonly config: GlobalLanguageServiceConfig;

  constructor(config: GlobalLanguageServiceConfig) {
    config.baseURL = sanitizeBaseUrl(config.baseURL);

    this.checkClient = new MetisCheckClient(config);
    this.nlpClient = new MetisNlpClient(config);
    this.translateClient = new MetisTranslateClient(config);
    this.genAiClient = new MetisGenAiClient(config);
    this.config = config;
  }

  public createChecker(options: LanguageCheckerFactoryOptions): LanguageChecker {
    return this.checkClient.createChecker({ ...this.config, ...options });
  }

  public async check(text: string, options: Checkable[], abort: AbortSignal): Promise<CheckResult> {
    const context: LanguageCheckOptions = {
      application: this.config.application,
      zone: this.config.zone,
      check: options,
    };

    return this.checkClient.check(text, context, abort);
  }

  public async inflections(word: string, partOfSpeech: "NOUN" | "VERB" | "ADJ" | "ADV", abort: AbortSignal): Promise<InflectionsResponse> {
    return this.nlpClient.inflections(word, partOfSpeech, abort);
  }

  public async predict(text: string, maxGeneratedWords: number, abort?: AbortSignal): Promise<PredictResponse> {
    return this.genAiClient.predict(text, maxGeneratedWords, abort);
  }

  public async edit(action: GenAiEditAction, text: string, context: string | undefined, requirements: string | undefined, abort?: AbortSignal): Promise<string> {
    return this.genAiClient.edit({ action, text, context, requirements }, abort);
  }

  public async prompt(model: GenAiModel, prompt: string, abort?: AbortSignal): Promise<string> {
    return this.genAiClient.prompt(model, prompt, abort);
  }

  public async translateTextToEnglish(text: string, abort?: AbortSignal): Promise<string> {
    return this.translateClient.translateTextToEnglish(text, abort);
  }

  public async translateHtmlToEnglish(html: string, abort?: AbortSignal): Promise<string> {
    return this.translateClient.translateHtmlToEnglish(html, abort);
  }

  public async translateText(text: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<string> {
    return this.translateClient.translateText(text, sourceLanguage, targetLanguage, abort);
  }

  public async translateHtml(html: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<string> {
    return this.translateClient.translateHtml(html, sourceLanguage, targetLanguage, abort);
  }

  public async translateToEnglish(content: string, contentType: string, abort?: AbortSignal): Promise<string>;
  public async translateToEnglish(content: ArrayBuffer, contentType: string, abort?: AbortSignal): Promise<ArrayBuffer>;
  public async translateToEnglish(content: string | ArrayBuffer, contentType: string, abort?: AbortSignal): Promise<string | ArrayBuffer> {
    return this.translateClient.translateToEnglish(content, contentType, abort);
  }

  public async translate(content: string, contentType: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<string>;
  public async translate(content: ArrayBuffer, contentType: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<ArrayBuffer>;
  public async translate(content: string | ArrayBuffer, contentType: string, sourceLanguage: TranslateLanguage, targetLanguage: TranslateLanguage, abort?: AbortSignal): Promise<string | ArrayBuffer> {
    return this.translateClient.translate(content, contentType, sourceLanguage, targetLanguage, abort);
  }
}

export interface LanguageServiceConfig extends GlobalLanguageServiceConfig {
  practice: string;
  profile: string;
}

export class LanguageService extends GlobalLanguageService {
  protected readonly wordClient: MetisWordClient;
  protected readonly config: LanguageServiceConfig;

  constructor(config: LanguageServiceConfig) {
    config.baseURL = sanitizeBaseUrl(config.baseURL);

    super(config);

    this.config = config;
    this.wordClient = new MetisWordClient(config);
  }

  public async fetchProfileWords(start = 0, limit = 100, abort: AbortSignal): Promise<string[]> {
    return this.wordClient.fetchProfileWords({
      networkID: this.config.practice,
      profile: this.config.profile,
      zone: this.config.zone,
    }, start, limit, abort);
  }

  public async addProfileWord(word: string, abort: AbortSignal): Promise<ProfileWord> {
    return this.wordClient.addProfileWord({
      networkID: this.config.practice,
      profile: this.config.profile,
      zone: this.config.zone,
      word,
    }, abort);
  }

  public async removeProfileWord(word: string, abort: AbortSignal): Promise<void> {
    return this.wordClient.removeProfileWord({
      networkID: this.config.practice,
      profile: this.config.profile,
      zone: this.config.zone,
      word,
    }, abort);
  }
}

export function globalLanguageService(config: GlobalLanguageServiceConfig): GlobalLanguageService {
  const requestHandler: RequestHandler = {
    onBeforeRequest: async (requestConfig) => {
      const { headers, ...rest } = requestConfig;
      headers.setAuthorization(`Bearer ${config.accessToken.accessToken}`);
      return { headers, ...rest };
    },
  };

  return new GlobalLanguageService({
    ...config,
    headers: config.headers,
    requestHandler,
  });
}

export function languageService(config: LanguageServiceConfig): LanguageService {
  const requestHandler: RequestHandler = {
    onBeforeRequest: async (requestConfig) => {
      const { headers, ...rest } = requestConfig;
      headers.setAuthorization(`Bearer ${config.accessToken.accessToken}`);
      return { headers, ...rest };
    },
  };

  return new LanguageService({
    ...config,
    headers: config.headers,
    requestHandler,
  });
}
