import { useState } from "react";
import { ulid } from "ulid";
import { RichText, SnippetFilterSet, SnippetTemplate, SnippetTemplateFilterSet, SpeechRuleCategory } from "@remhealth/apollo";
import { Alert, Button, Classes, InputGroup, SelectInput, useAbort } from "@remhealth/ui";
import { BeforeLeavePrompt, Form, FormActions, FormContent, Yup, speechRuleCategoryText } from "@remhealth/core";
import { Compose, EditableContent, Toolbar } from "@remhealth/compose";
import { Text } from "~/text";
import { useRegistry } from "~/contexts";
import BellsPlaceholders from "./bellsPlaceholders";
import { CategoryGroup, FieldGroup, Modal, QuillContainer, TextGroup } from "./snippetTemplateDialog.styles";

interface SnippetTemplateDialogFields {
  name: string;
  text: RichText;
  category: SpeechRuleCategory | undefined;
}

const nameMaxLength = 30;
const textMaxLength = 3000;

const SnippetTemplateDialogSchema = Yup.object<SnippetTemplateDialogFields>({
  name: Yup.string().label(Text.Snippets).required(Text.SnippetErrorMessage).trim().max(nameMaxLength)
    .matches(/^([\w-]+)$/, Text.SnippetErrorMessage),
  text: Yup.object<RichText>()
    .label(Text.FullText)
    .required()
    .test("required", Text.SnippetFullTextErrorMessage, function(value: RichText | undefined) {
      return !!value?.plainText?.trim();
    })
    .test("max-length", `Cannot be more than ${textMaxLength} characters.`, function(value: RichText | undefined) {
      return !value?.plainText || value.plainText.length <= textMaxLength;
    }),
  category: Yup.mixed<SpeechRuleCategory>().label(Text.ApplicableCategory),
});

export interface SnippetTemplateDialogProps {
  isOpen: boolean;
  snippetTemplate?: SnippetTemplate;
  onClose?: () => void;
  onSave?: (snippetTemplate: SnippetTemplate) => void;
}

export const SnippetTemplateDialog = (props: SnippetTemplateDialogProps) => {
  const { isOpen, snippetTemplate, onClose, onSave } = props;

  const registry = useRegistry();
  const abort = useAbort();

  const [confirmLeave, setConfirmLeave] = useState(false);
  const [conflictedCount, setConflictedCount] = useState(0);

  return (
    <Form<SnippetTemplateDialogFields>
      initialValues={{
        name: snippetTemplate?.name.trim() ?? "",
        text: {
          value: snippetTemplate?.text.value ?? "",
          plainText: snippetTemplate?.text.plainText ?? "",
        },
        category: snippetTemplate?.category,
      }}
      validationSchema={SnippetTemplateDialogSchema}
      onSubmit={handleFormSubmit}
    >
      {form => {
        return (
          <>
            <Modal isOpen={isOpen} title={Text.Snippet} onClose={() => handleClose(form, form.dirty)}>
              <div className={Classes.DIALOG_BODY}>
                <FieldGroup field={form.fields.name} label={Text.Snippet}>
                  <InputGroup autoFocus large autoComplete="off" field={form.fields.name} maxLength={nameMaxLength} />
                </FieldGroup>
                <TextGroup field={form.fields.text} helperText={Text.DropInPlaceholderHelperText} label={Text.FullText}>
                  <QuillContainer>
                    <Compose field={form.fields.text}>
                      <EditableContent />
                      <BellsPlaceholders />
                      <Toolbar />
                    </Compose>
                  </QuillContainer>
                </TextGroup>
                <CategoryGroup label={Text.ApplicableCategory}>
                  <SelectInput<SpeechRuleCategory>
                    large
                    field={form.fields.category}
                    items={Object.values(SpeechRuleCategory)}
                    optionRenderer={item => speechRuleCategoryText[item]}
                  />
                </CategoryGroup>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    large
                    minimal
                    disabled={form.isSubmitting}
                    intent="primary"
                    label={Text.Cancel}
                    onClick={() => handleClose(form, form.dirty)}
                  />
                  <Button
                    large
                    disabled={form.isSubmitting}
                    intent="primary"
                    label={Text.Save(Text.Snippet)}
                    onClick={() => form.submitForm()}
                  />
                </div>
              </div>
            </Modal>
            <BeforeLeavePrompt
              isOpen={isOpen && confirmLeave}
              when={form.dirty && !form.isSubmitting}
              onCancelLeave={handleCancelLeave}
              onConfirmLeave={() => handleConfirmLeave(form)}
            />
            <Alert
              cancelButtonText="No, let me update the name"
              confirmButtonText="Yes, override"
              header={<>{Text.Snippet} Name Override</>}
              intent="warning"
              isOpen={conflictedCount > 0}
              onClose={() => handleConfirmWarningDialogClose()}
              onConfirm={() => handleConfirmWarningDialogConfirm(form)}
            >
              <p>Saving this will conflict with {conflictedCount} user{conflictedCount > 1 ? "s" : ""}</p>
              <p>Are you sure you want to continue?</p>
            </Alert>
          </>
        );
      }}
    </Form>
  );

  function handleClose(form: FormActions<SnippetTemplateDialogFields>, dirty?: boolean) {
    if (dirty) {
      setConfirmLeave(true);
    } else {
      form.resetForm();
      onClose?.();
    }
  }

  function handleConfirmWarningDialogClose() {
    setConflictedCount(0);
  }

  async function handleConfirmWarningDialogConfirm(form: FormContent<SnippetTemplateDialogFields>) {
    setConflictedCount(0);
    triggerSave(form.values);
    handleClose(form);
  }

  async function handleFormSubmit(values: SnippetTemplateDialogFields, formActions: FormActions<SnippetTemplateDialogFields>) {
    const name = values.name.trim();

    const nameFilter: SnippetTemplateFilterSet = {
      id: snippetTemplate?.id ? {
        notIn: [snippetTemplate.id],
      } : undefined,
      name: {
        equalTo: name,
      },
    };

    const duplicateCount = await fetchConflictedSnippetTemplateCount([{
      ...nameFilter,
    }]);

    if (duplicateCount > 0) {
      formActions.setFieldError("name", Text.DuplicateNameError(Text.Snippet));
      return;
    }

    await triggerSave(values);

    handleClose(formActions);
  }

  async function triggerSave(values: SnippetTemplateDialogFields) {
    const name = values.name.trim();
    const value = values.text.value?.trim();
    const plainText = values.text.plainText?.trim();

    const data: SnippetTemplate = {
      id: snippetTemplate?.id ?? ulid(),
      resourceType: "SnippetTemplate",
      display: name,
      name,
      text: { value, plainText },
      category: values.category ?? SpeechRuleCategory.AnyService,
    };

    await registry.snippetTemplates.update(data);
    onSave?.(data);
  }

  async function fetchConflictedSnippetTemplateCount(filters: SnippetFilterSet[]) {
    return await registry.snippetTemplates.queryCount({
      filters,
      abort: abort.signal,
    });
  }

  function handleConfirmLeave(form: FormActions<SnippetTemplateDialogFields>) {
    setConfirmLeave(false);
    form.resetForm();
    onClose?.();
  }

  function handleCancelLeave() {
    setConfirmLeave(false);
  }
};
