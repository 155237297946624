import { New, SpeechRuleCategory, SpeechRuleTemplate } from "@remhealth/apollo";
import { type Complete, Yup, speechRuleSchema } from "@remhealth/core";
import { type DiscriminatedProperty, FormFields } from "@remhealth/ui";

export type SpeechRuleTemplateForm = Omit<New<SpeechRuleTemplate>, "rule"> & {
  rule: Complete<SpeechRuleTemplate["rule"]>;
};

export function speechRuleTemplateSchema(strict: boolean) {
  return Yup.object<SpeechRuleTemplateForm>({
    rule: speechRuleSchema(strict),
    category: Yup.mixed<SpeechRuleCategory>()
      .label("Applicable Category")
      .required(),
  }).strict(true);
}

export function isRuleForm<T extends SpeechRuleType>(form: FormFields<SpeechRuleTemplateForm>, ...type: T[]): form is RuleForm<T> {
  return (type as string[]).includes(form.rule.fields.type.value);
}

type SpeechRuleType = SpeechRuleTemplate["rule"]["type"];
type DiscriminatedSpeechRule<TRuleType extends SpeechRuleType> = DiscriminatedProperty<SpeechRuleTemplateForm, "rule", "type", TRuleType>;

export type RuleForm<TRuleType extends SpeechRuleType> = FormFields<DiscriminatedSpeechRule<TRuleType>>;
