import React, { useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import FileSaver from "file-saver";
import { BlockedPerson, Confirm, Envelope, Export, Import, MoreVertical, Refresh, Tag, WarningSign } from "@remhealth/icons";

import {
  Communication,
  CommunicationFilterSet,
  ContactMethod,
  Instant,
  LocalDate,
  PatientPopulationLevel,
  Practice,
  Practitioner,
  PractitionerSortField,
  Recipient,
  Reference,
  SortDirection,
  knownCodings,
  securityRoleIds
} from "@remhealth/apollo";

import {
  Button,
  Checkbox,
  ColumnFilter,
  DateFormats,
  Ellipsize,
  GridFeed,
  GroupColumnFilter,
  Icon,
  IconButton,
  IconSize,
  InputGroup,
  InteractiveRow,
  ListGrouping,
  Menu,
  MenuDivider,
  MenuItem,
  NonIdealIcon,
  Popover,
  SortButton,
  Spinner,
  Switch,
  Tooltip,
  defaultGridViewPageSize,
  useAbort,
  useDebouncer,
  useStateRef
} from "@remhealth/ui";

import { TrackingService, UserAccessStatus, getProductFlag, useApollo, usePracticePreferences } from "@remhealth/host";
import { useEhr } from "~/services";
import { Text } from "~/text";
import { getLabeling } from "~/labeling";
import { BellsRoleFilter, createPractitionerFilters, useFeed, usePractitionersViewWithStore, useStore } from "~/stores";
import { useErrorHandler, useNotifier } from "~/app";
import { useAnalytics } from "~/analytics";
import { PractitionerBanner } from "~/avatars/practitionerBanner";
import { Constants } from "~/constants";
import { DataMenuItem } from "~/components/dataMenuItem";
import { useSearchParamList, useSearchParamSort, useSearchParamText } from "~/routing";
import { CaptureEmail } from "./captureEmail";
import { ClearPin } from "./clearPin";

import { EhrUsers } from "./ehrUsers";
import { PullLoadingDialog } from "./pullLoadingDialog";
import { RemoveSupervisorRole } from "./removeSupervisorRole";
import { UserTags } from "./userTags";
import { getUserEmail } from "./utils";
import { BulkUpdateUserEmails } from "./bulkUpdateUserEmails";
import { Grid, SearchInputFormGroup } from "./common.styles";
import {
  ActionButtons,
  ActionsHeader,
  CheckboxCell,
  CheckboxHeader,
  EmailCell,
  NameHeader,
  NiamCell,
  NiamHeader,
  PermissionsHeader,
  SearchBarContent,
  SendInvitesMessage,
  SourceBar
} from "./availableUsers.styles";

const pageKey = "practitioners";

type Action = "capture-email" | "clear-pin" | "remove-supervisor-role";
interface UserAction {
  user: Practitioner;
  action: Action;
}

export interface AvailableUsersProps {
  practice: Practice;
  bellsRoute: string;
  ehrRoute: string;
  militaryTime: boolean;
  allowExport?: boolean;
  tracking?: TrackingService;
  currentUser?: Practitioner;
  showExtensions?: boolean;
  allowSupervisorImport?: boolean;
  onUserClick?: (user: Practitioner) => void;
}

const statusValues = Object.values(UserAccessStatus);
const sortableFields = Object.values(PractitionerSortField);

export function AvailableUsers(props: AvailableUsersProps) {
  const {
    allowExport = false,
    practice,
    bellsRoute,
    ehrRoute,
    militaryTime,
    tracking,
    currentUser,
    showExtensions = false,
    allowSupervisorImport = false,
    onUserClick,
  } = props;

  const apollo = useApollo();
  const store = useStore();
  const ehr = useEhr();
  const handleError = useErrorHandler();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const notification = useNotifier();
  const abort = useAbort();
  const debouncer = useDebouncer(100);
  const practicePreference = usePracticePreferences();

  const labels = getLabeling(practice.product, practicePreference);
  const searchByLastName = getProductFlag(practice, "SearchUserByLastName");
  const showUserAccessStatus = getProductFlag(practice, "ShowUserAccessStatus");
  const showStaffUsername = getProductFlag(practice, "ShowStaffUsername");
  const showStaffHireDate = getProductFlag(practice, "ShowStaffHireDate");
  const canConfigureSupervisors = getProductFlag(practice, "AllowConfigureSupervisors");
  const captureWorkEmail = getProductFlag(practice, "CaptureWorkEmail");
  const niamEnabled = practice.features.includes("LoginNiamProd") || practice.features.includes("LoginNiamUat") || practice.features.includes("LoginNiamDev");

  const ambientListeningEnabled = !practice.maxAmbientListenersAllowed ? false : practice.maxAmbientListenersAllowed > 0;

  const isEhrRoute = useMatch(ehrRoute);
  const searchEhr = useStateRef(!!isEhrRoute);

  const roles = useMemo(getRoles, [ambientListeningEnabled]);

  const { searchText, query, setSearchText } = useSearchParamText();

  const [statusFilters, setStatusFilters] = useSearchParamList({
    name: searchEhr.current ? "status-ehr" : "status",
    validValues: statusValues,
    defaultValues: showUserAccessStatus ? ["Active", "Pending"] : undefined,
    storageKey: searchEhr.current ? undefined : "users-status-filters",
  });

  const [roleFilters, setRoleFilters] = useSearchParamList({
    name: searchEhr.current ? "role-ehr" : "role",
    validValues: isValidRoleFilter,
    storageKey: searchEhr.current ? undefined : "users-role-filters",
  });

  const [sortPredicate, setSortPredicate] = useSearchParamSort(sortableFields, {
    field: PractitionerSortField.Name,
    direction: SortDirection.Ascending,
  });

  const [pageSize, setPageSize] = useState(defaultGridViewPageSize);
  const [allSelected, setAllSelected] = useState(false);
  const [recentCommunications, setRecentCommunications] = useState(new Map<string, Communication | null>());
  const [isInvitingFlags, setIsInvitingFlags] = useState(new Map<string, boolean>());
  const [usersToInvite, setUsersToInvite] = useState(new Map<string, Practitioner>());
  const lastSignedIn = useStateRef(new Map<string, DateTime | null>());
  const [userAction, setUserAction] = useState<UserAction | null>(null);
  const [pullingUser, setPullingUser] = useState("");
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [showBulkUpdateEmails, setShowBulkUpdateEmails] = useState(false);
  const [showSupervisorImportDialog, setShowSupervisorImportDialog] = useState(false);
  const [loadingSuperviseesCount, setLoadingSuperviseesCount] = useState(false);
  const [loadingAmbientListeningUsersCount, setLoadingAmbientListeningUsersCount] = useState(false);
  const [exporting, setExporting] = useState(false);

  const isSupport = !currentUser || currentUser.profile.roles.some(r => r.id === securityRoleIds.Support);

  const view = usePractitionersViewWithStore(store.practitioners, sortPredicate, {
    statuses: statusFilters,
    roles: roleFilters,
    query,
    // Hide support user unless logged in as support user
    includeSupport: isSupport,
  });
  const feed = useFeed(view);

  // Clear selection(s) if present on page change. As we have scope select & send to a single page for now.
  useEffect(() => {
    if (allSelected || usersToInvite.size > 0) {
      setAllSelected(false);
      setUsersToInvite(new Map());
    }
  }, [feed.page]);

  // Update tab if browser URL changes
  useEffect(() => {
    if (!!isEhrRoute !== searchEhr.current) {
      searchEhr.set(!!isEhrRoute);
    }
  }, [isEhrRoute])

  const actionButtons = allowSupervisorImport
    ? (
      <Popover
        content={(
          <Menu>
            <MenuItem text="Import Users" onClick={openImportDialog} />
            <MenuItem text="Import Users and Supervisors" onClick={openSupervisorImportDialog} />
          </Menu>
        )}
        placement="bottom-end"
      >
        <Button outlined intent="primary" label="Bulk Import" rightIcon="chevron-down" />
      </Popover>
    ) : <Button outlined intent="primary" label="Bulk Import Users" onClick={openImportDialog} />;

  return (
    <>
      <SourceBar ehr={searchEhr.current} productLabel={labels.Product} onChange={handleSourceChange} />
      <SearchInputFormGroup helperText={searchEhr.current && searchByLastName ? Text.SearchByLastName : undefined}>
        <SearchBarContent>
          <InputGroup
            clearable
            fill
            large
            soft
            placeholder={searchEhr.current ? Text.SearchProductUsers(labels) : Text.SearchUsers}
            rightElement={feed.loading && !exporting ? <Spinner intent="primary" size={18} /> : undefined}
            type="search"
            value={searchText}
            onChange={setSearchText}
          />
          {searchEhr.current && actionButtons}
          {!searchEhr.current && allowExport && (
            <>
              {isSupport && <Button minimal icon={<Import />} label="Bulk Update Emails" onClick={handleBulkUpdateEmails} />}
              <Button minimal icon={<Export />} intent="primary" label="Export" loading={exporting} onClick={handleExport} />
              <BulkUpdateUserEmails
                isOpen={showBulkUpdateEmails}
                onClose={() => setShowBulkUpdateEmails(false)}
              />
            </>
          )}
        </SearchBarContent>
      </SearchInputFormGroup>
      {!searchEhr.current
        ? (
          <Grid>
            <GridFeed<Practitioner>
              compact
              fill
              stickyHeader
              defaultPageSize={pageSize}
              feed={feed}
              headerRenderer={headerRenderer}
              interactive={!!onUserClick}
              noResults={renderNoRecords}
              pageKey={pageKey}
              rowRenderer={rowRenderer}
              onPageSizeChanged={setPageSize}
              onViewChanged={handleViewChange}
            />
          </Grid>
        )
        : (
          <EhrUsers
            isImportOpen={showImportDialog}
            isSupervisorImportOpen={showSupervisorImportDialog}
            practice={practice}
            searchText={searchText}
            onCloseImport={closeImportDialog}
            onCloseSupervisorImport={closeSupervisorImportDialog}
            onImport={enableImportedUser}
          />
        )}
      {usersToInvite.size > 0 && (
        <SendInvitesMessage>
          <div>{usersToInvite.size} {usersToInvite.size === 1 ? "item" : "items"} selected</div>
          <Button icon={<Envelope />} intent="primary" label="Send invitation to selected users" loading={isInvitingFlags.size > 0 && usersToInvite.size > 0} onClick={handleMultipleInvites} />
        </SendInvitesMessage>
      )}
      {userAction && userAction.action === "capture-email" && (
        <CaptureEmail
          isOpen
          captureWorkEmail={captureWorkEmail}
          store={store.practitioners}
          user={userAction.user}
          onClose={resetUserAction}
        />
      )}
      {userAction && userAction.action === "clear-pin" && (
        <ClearPin
          isOpen
          user={userAction.user}
          onClose={resetUserAction}
        />
      )}
      <PullLoadingDialog name={pullingUser} practice={practice} onCancel={handleCancelResync} />
      {userAction && userAction.action === "remove-supervisor-role" && (
        <RemoveSupervisorRole
          isOpen
          user={userAction.user}
          onClose={resetUserAction}
        />
      )}
    </>
  );

  function isValidRoleFilter(value: unknown): value is BellsRoleFilter {
    return roles.some(r => r.items.includes(value as BellsRoleFilter));
  }

  function getRoles(): ListGrouping<BellsRoleFilter>[] {
    const filters: ListGrouping<BellsRoleFilter>[] = [
      { key: "Bells", items: ["enabled", "bells-disabled"] },
      { key: "Admin", items: ["admins", "non-admins"] },
      { key: "Supervisor", items: ["supervisors", "non-supervisors"] },
      { key: `All ${labels.Patients}`, items: ["careteam-disabled", "careteam-enabled"] },
    ];

    if (ambientListeningEnabled) {
      filters.push({ key: Text.AmbientListening, items: ["ambient-listening-enabled", "ambient-listening-disabled"] });
    }

    return filters;
  }

  function openImportDialog() {
    setShowImportDialog(true);
  }

  function closeImportDialog() {
    setShowImportDialog(false);
  }

  function openSupervisorImportDialog() {
    setShowSupervisorImportDialog(true);
  }

  function closeSupervisorImportDialog() {
    setShowSupervisorImportDialog(false);
  }

  function handleSourceChange(ehr: boolean) {
    searchEhr.set(ehr);

    const route = ehr ? ehrRoute : bellsRoute;
    const search = new URLSearchParams();

    if (query) {
      search.set("query", query);
    }

    navigate(search.size > 0 ? `${route}?${search.toString()}` : route);
  }

  function startClearPin(user: Practitioner) {
    setUserAction({
      user,
      action: "clear-pin",
    });
  }

  function startEmailCapture(user: Practitioner) {
    setUserAction({
      user,
      action: "capture-email",
    });
  }

  function resetUserAction() {
    setUserAction(null);
  }

  function renderNoRecords() {
    const colSpan = 5
      + (analytics ? 1 : 0)
      + (showStaffUsername ? 1 : 0)
      + (showStaffHireDate ? 1 : 0);
    return (
      <tr>
        <td colSpan={colSpan}>
          <NonIdealIcon
            description={`No matching users found. Try searching ${labels.Product} instead.`}
            icon={<BlockedPerson />}
            intent="primary"
            title={Text.NoUsers}
          />
        </td>
      </tr>
    );
  }

  function headerRenderer() {
    return (
      <tr>
        <CheckboxHeader>
          <Checkbox checked={allSelected} onChange={handleAllSelected} />
        </CheckboxHeader>
        <NameHeader>
          <SortButton
            label="Name"
            sort={sortPredicate.field === PractitionerSortField.Name ? sortPredicate.direction : "Unspecified"}
            onClick={handleSortByName}
          />
          {showUserAccessStatus && (
            <ColumnFilter<UserAccessStatus>
              aria-label="Filter by Status"
              items={statusValues}
              labelRenderer={getStatusLabel}
              selectedItems={statusFilters}
              onChange={setStatusFilters}
            />
          )}
        </NameHeader>
        <th>
          <SortButton
            label="Email"
            sort={sortPredicate.field === PractitionerSortField.Email ? sortPredicate.direction : "Unspecified"}
            onClick={handleSortByEmail}
          />
        </th>
        {showStaffUsername && <th>{Text.Username}</th>}
        {showStaffHireDate && <th>{Text.HireDate}</th>}
        {niamEnabled && <NiamHeader>{Text.NiamEnabled}</NiamHeader>}
        {analytics && <th>{Text.LastSignedIn}</th>}
        <PermissionsHeader>
          Permissions
          <GroupColumnFilter<BellsRoleFilter>
            aria-label="Filter by Permission"
            groups={roles}
            labelRenderer={labelRenderer}
            selectedItems={roleFilters}
            titleRenderer={titleRenderer}
            onChange={setRoleFilters}
          />
        </PermissionsHeader>
        <ActionsHeader />
      </tr>
    );
  }

  function handleSortByName() {
    handleSortBy(PractitionerSortField.Name);
  }

  function handleSortByEmail() {
    handleSortBy(PractitionerSortField.Email);
  }

  function handleSortBy(field: PractitionerSortField) {
    let direction: SortDirection = SortDirection.Ascending;
    if (sortPredicate.field === field) {
      direction = getReverseSortDirection(sortPredicate.direction);
    }
    setSortPredicate({ field, direction });
  }

  function getReverseSortDirection(sortDirection?: SortDirection) {
    return sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
  }

  function titleRenderer(key: string | null) {
    return (
      <div>{key}</div>
    );
  }

  function labelRenderer(filter: BellsRoleFilter) {
    switch (filter) {
      case "enabled":
        return Text.BellsEnabled;
      case "supervisors":
        return Text.Supervisor;
      case "non-supervisors":
        return Text.NonSupervisor;
      case "bells-disabled":
        return Text.BellsDisabled;
      case "admins":
        return Text.BellsAdmins;
      case "non-admins":
        return Text.BellsNonAdmins;
      case "careteam-disabled":
        return Text.AllPatientsEnabled(labels);
      case "careteam-enabled":
        return Text.AllPatientsDisabled(labels);
      case "ambient-listening-disabled":
        return Text.AmbientListeningDisabled;
      case "ambient-listening-enabled":
        return Text.AmbientListeningEnabled;
    }
  }

  function rowRenderer(item: Practitioner) {
    const email = getUserEmail(item);
    const hasBellsRole = item.profile.roles.some(r => r.id === securityRoleIds.Bells);
    const hasAdminRole = item.profile.roles.some(r => r.id === securityRoleIds.Administrative);
    const lastSignedInDate = lastSignedIn.current.get(item.id);
    const niamUsername = item.extensions?.find(e => e.name === "niam")?.value;
    const hasBellsLicense = item.profile.licenses.includes("Bells");

    const separatedDate = DateFormats.date(LocalDate.toDate(item.tenure.end));

    return (
      <InteractiveRow key={item.id} data-rowid={`row-${item.id}`} onClick={() => onUserClick?.(item)}>
        {rowProps => (
          <>
            <CheckboxCell onClick={event => event.stopPropagation()}>
              <Checkbox
                checked={usersToInvite.has(item.id)}
                disabled={!eligibleForInvite(item)}
                onChange={(ev) => handleSelectUserToInvite(ev, item)}
              />
            </CheckboxCell>
            <td><PractitionerBanner practitioner={item} scale="small" /></td>
            <EmailCell>{email && <Ellipsize>{email}</Ellipsize>}</EmailCell>
            {showStaffUsername && <td>{item.extensions?.find(e => e.name === "username")?.value}</td>}
            {showStaffHireDate && (
              <td>
                {DateFormats.date(LocalDate.toDate(item.tenure.start)) || (separatedDate && "N/A")}
                {separatedDate && <> &ndash; {separatedDate}</>}
              </td>
            )}
            {niamEnabled && <NiamCell>{niamUsername ? <Tooltip content={niamUsername}><Icon icon={<Confirm />} intent="primary" size={IconSize.LARGE} /></Tooltip> : ""}</NiamCell>}
            {analytics && <td>{DateFormats.date(lastSignedInDate)}</td>}
            <td><UserTags user={item} /></td>
            <td onClick={event => event.stopPropagation()}>
              <ActionButtons>
                {(showExtensions || isSupport) && (
                  <Popover {...rowProps.popoverProps} content={renderExtensions(item)}>
                    <IconButton elevated tooltip aria-label="Show Tags" className="hover-only" icon={<Tag />} />
                  </Popover>
                )}
                {!email ? (
                  <IconButton
                    elevated
                    tooltip
                    aria-label="Send Invite"
                    className="hover-only"
                    disabled={!hasBellsRole}
                    icon={<Envelope />}
                    onClick={() => startEmailCapture(item)}
                  />
                ) : isInvitingFlags.get(item.id)
                  ? <Spinner intent="primary" size={15} />
                  : renderSendButton(item)}
                <Popover
                  {...rowProps.popoverProps}
                  content={(
                    <Menu>
                      {renderBellsSwitch(item)}
                      {hasBellsLicense && (
                        <>
                          <MenuDivider />
                          {renderAdminBellsSwitch(item)}
                        </>
                      )}
                      {hasBellsLicense && (
                        <>
                          <MenuDivider />
                          {renderSupervisorSwitch(item)}
                        </>
                      )}
                      {ambientListeningEnabled && (
                        <>
                          <MenuDivider />
                          {renderAmbientListeningSwitch(item)}
                        </>
                      )}
                      {hasBellsLicense && !hasAdminRole && (
                        <>
                          <MenuDivider />
                          {renderAllPatientsSwitch(item)}
                        </>
                      )}
                      <MenuDivider />
                      <MenuItem text={Text.ClearPin} onClick={() => startClearPin(item)} />
                      <MenuDivider />
                      <MenuItem icon={<Refresh />} text={Text.Resync} onClick={() => handlePull(item)} />
                    </Menu>
                  )}
                >
                  <IconButton elevated tooltip aria-label="More Options" icon={<MoreVertical />} />
                </Popover>
              </ActionButtons>
            </td>
          </>
        )}
      </InteractiveRow>
    );
  }

  function renderExtensions(practitioner: Practitioner) {
    return (
      <Menu>
        <DataMenuItem text="Practitioner ID" value={practitioner.id} />
        <MenuDivider title="Identifiers" />
        {(practitioner.identifiers ?? []).map((identifier, index) => (
          <DataMenuItem key={index} text={identifier.system ?? identifier.type?.codings[0]?.system} value={identifier.value} />
        ))}
        {(practitioner.identifiers ?? []).length === 0 && <DataMenuItem passive text="None" />}
        <MenuDivider title="Extensions" />
        {(practitioner.extensions ?? []).map((extension, index) => (
          <DataMenuItem key={index} text={extension.name} value={extension.value} />
        ))}
        {(practitioner.extensions ?? []).length === 0 && <DataMenuItem passive text="None" />}
      </Menu>
    );
  }

  function renderAllPatientsSwitch(practitioner: Practitioner) {
    const content = (
      <Switch
        alignIndicator="right"
        checked={practitioner.patientPopulationOverride
          ? practitioner.patientPopulationOverride === "All"
          : practicePreference.patientPopulationLevel === "All"}
        innerLabel={Text.No}
        innerLabelChecked={Text.Yes}
        label={`All ${labels.Patients}`}
        onChange={(ev) => handleAllPatientsToggle(ev, practitioner)}
      />
    );
    return <MenuItem shouldDismissPopover={false} text={content} />;
  }

  function handleAllPatientsToggle(event: React.FormEvent<HTMLInputElement>, practitioner: Practitioner) {
    const { checked } = event.currentTarget;

    tracking?.track("User Management Toggle", { toggleName: "All Patients", context: checked ? "Yes" : "No" });

    // Remove override if matching org preference
    const userLevel = checked ? PatientPopulationLevel.All : PatientPopulationLevel.CareTeam;
    const override = userLevel === practicePreference.patientPopulationLevel
      ? undefined
      : userLevel;

    store.practitioners.upsert({
      ...practitioner,
      patientPopulationOverride: override,
    });
  }

  function renderBellsSwitch(practitioner: Practitioner) {
    const disabled = practitioner.id === currentUser?.id;
    const hasBellsLicense = practitioner.profile.licenses.includes("Bells");

    const content = (
      <Switch
        alignIndicator="right"
        checked={hasBellsLicense}
        disabled={disabled}
        innerLabel="Disabled"
        innerLabelChecked="Enabled"
        label="Bells"
        onChange={handleChange}
      />
    );

    return (
      <MenuItem disabled={disabled} shouldDismissPopover={false} text={content} />
    );

    function handleChange(event: React.FormEvent<HTMLInputElement>) {
      if (event.currentTarget.checked) {
        tracking?.track("User Management Toggle", { toggleName: "Bells Enabled", context: "Yes" });
        toggleBellsAccess(practitioner, true);
      } else {
        tracking?.track("User Management Toggle", { toggleName: "Bells Enabled", context: "No" });
        toggleBellsAccess(practitioner, false);
      }
    }
  }

  function renderAdminBellsSwitch(practitioner: Practitioner) {
    const disabled = practitioner.id === currentUser?.id;
    const hasAdminRole = practitioner.profile.roles.some(r => r.id === securityRoleIds.Administrative);

    const content = (
      <Switch
        alignIndicator="right"
        checked={hasAdminRole}
        disabled={disabled}
        innerLabel={Text.No}
        innerLabelChecked={Text.Yes}
        label="Admin"
        onChange={handleChange}
      />
    );

    return (
      <MenuItem disabled={disabled} shouldDismissPopover={false} text={content} />
    );

    function handleChange(event: React.FormEvent<HTMLInputElement>) {
      if (event.currentTarget.checked) {
        tracking?.track("User Management Toggle", { toggleName: "Admin", context: "Yes" });
        // Add role, unless already present
        if (!hasAdminRole) {
          practitioner.profile.roles.push({ resourceType: "SecurityRole", id: securityRoleIds.Administrative, display: "Administrative" });
        }
      } else {
        tracking?.track("User Management Toggle", { toggleName: "Admin", context: "No" });
        // Remove admin role
        practitioner.profile.roles = practitioner.profile.roles.filter(r => r.id !== securityRoleIds.Administrative);
      }

      store.practitioners.upsert(practitioner, 1000);
    }
  }

  function renderSupervisorSwitch(practitioner: Practitioner) {
    const hasSupervisorRole = practitioner.profile.roles.some(r => r.id === securityRoleIds.Supervisor);

    const content = loadingSuperviseesCount
      ? <Spinner intent="primary" size={18} />
      : (
        <Switch
          alignIndicator="right"
          checked={hasSupervisorRole}
          disabled={!canConfigureSupervisors}
          innerLabel={Text.No}
          innerLabelChecked={Text.Yes}
          label={Text.Supervisor}
          onChange={handleChange}
        />
      );

    return <MenuItem disabled={!canConfigureSupervisors} shouldDismissPopover={false} text={content} />;

    async function handleChange(event: React.FormEvent<HTMLInputElement>) {
      if (event.currentTarget.checked) {
        tracking?.track("User Management Toggle", { toggleName: Text.Supervisor, context: "Yes" });
        // Add role, unless already present
        const hasSupervisorRole = practitioner.profile.roles.some(r => r.id === securityRoleIds.Supervisor);
        if (!hasSupervisorRole) {
          practitioner.profile.roles.push({ resourceType: "SecurityRole", id: securityRoleIds.Supervisor, display: "Supervisor" });
          store.practitioners.upsert(practitioner, 1000);
        }
      } else {
        tracking?.track("User Management Toggle", { toggleName: Text.Supervisor, context: "No" });

        // Remove supervisor role directly if no supervisees present
        const superviseesCount = await getSuperviseesCount(practitioner);
        if (superviseesCount === 0) {
          practitioner.profile.roles = practitioner.profile.roles.filter(r => r.id !== securityRoleIds.Supervisor);
          store.practitioners.upsert(practitioner, 1000);
        } else {
          // Show remove supervisor role dialog
          setUserAction({
            user: practitioner,
            action: "remove-supervisor-role",
          });
        }
      }
    }
  }

  function renderAmbientListeningSwitch(practitioner: Practitioner) {
    if (loadingAmbientListeningUsersCount) {
      return <MenuItem shouldDismissPopover={false} text={<Spinner intent="primary" size={18} />} />;
    }

    const hasAmbientListeningRole = practitioner.profile.roles.some(r => r.id === securityRoleIds.AmbientListening);

    const content = (
      <Switch
        alignIndicator="right"
        checked={hasAmbientListeningRole}
        innerLabel={Text.No}
        innerLabelChecked={Text.Yes}
        label={Text.AmbientListening}
        onChange={handleChange}
      />
    );

    return <MenuItem shouldDismissPopover={false} text={content} />;

    async function handleChange(event: React.FormEvent<HTMLInputElement>) {
      if (event.currentTarget.checked) {
        const ambientListeningUsersCount = await getAmbientListeningUsersCount();
        if (practice.maxAmbientListenersAllowed && ambientListeningUsersCount >= practice.maxAmbientListenersAllowed) {
          notification.show({
            icon: <WarningSign />,
            intent: "danger",
            message: Text.MaxAmbientListeningUsersWarning,
            timeout: 5000,
          });
          return;
        }

        const hasAmbientListeningRole = practitioner.profile.roles.some(r => r.id === securityRoleIds.AmbientListening);
        if (!hasAmbientListeningRole) {
          practitioner.profile.roles.push({ resourceType: "SecurityRole", id: securityRoleIds.AmbientListening, display: "AmbientListening" });
          store.practitioners.upsert(practitioner, 1000);
        }
      } else {
        practitioner.profile.roles = practitioner.profile.roles.filter(r => r.id !== securityRoleIds.AmbientListening);
        store.practitioners.upsert(practitioner, 1000);
      }
    }
  }

  async function enableImportedUser(reference: Reference<Practitioner>) {
    const practitioner = await store.practitioners.expand(reference);
    toggleBellsAccess(practitioner, true);
  }

  function toggleBellsAccess(practitioner: Practitioner, enable: boolean) {
    const hasBellsLicense = practitioner.profile.licenses.includes("Bells");
    const hasBellsRole = practitioner.profile.roles.some(r => r.id === securityRoleIds.Bells);
    const hasAmbientListeningRole = practitioner.profile.roles.some(r => r.id === securityRoleIds.AmbientListening);

    if (enable) {
      // Bells roles require bells license
      if (!hasBellsLicense) {
        practitioner.profile.licenses.push("Bells");
      }

      // Add role, unless already present
      if (!hasBellsRole) {
        practitioner.profile.roles.push({ resourceType: "SecurityRole", id: securityRoleIds.Bells, display: "Bells" });
      }
    } else {
      practitioner.profile.licenses = practitioner.profile.licenses.filter(l => l !== "Bells");
      practitioner.profile.roles = practitioner.profile.roles.filter(r => r.id !== securityRoleIds.Bells);

      // Ensure ambient listening is disabled while disabling Bells
      if (hasAmbientListeningRole) {
        practitioner.profile.roles = practitioner.profile.roles.filter(r => r.id !== securityRoleIds.AmbientListening);
      }
    }

    store.practitioners.upsert(practitioner, 1000);
  }

  function handleAllSelected(event: React.FormEvent<HTMLInputElement>) {
    const value = event.currentTarget.checked;

    if (value) {
      setAllSelected(value);

      const { page, items } = feed;
      const currrentPage = page === "last"
        ? items.length / pageSize
        : page;
      const startIndex = pageSize * (currrentPage - 1);
      const itemsInPage = items.slice(startIndex, startIndex + pageSize);
      const inviteableUsers = itemsInPage.filter(eligibleForInvite);

      inviteableUsers.forEach(user => usersToInvite.set(user.id, user));
      setUsersToInvite(new Map(usersToInvite));
    } else {
      setUsersToInvite(new Map());
      setAllSelected(value);
    }
  }

  function handleSelectUserToInvite(event: React.FormEvent<HTMLInputElement>, user: Practitioner) {
    const { checked } = event.currentTarget;
    if (checked && eligibleForInvite(user)) {
      usersToInvite.set(user.id, user);
      setUsersToInvite(new Map(usersToInvite));
    } else {
      usersToInvite.delete(user.id);
      setUsersToInvite(new Map(usersToInvite));
    }
  }

  function renderSendButton(item: Practitioner) {
    const hasBellsRole = item.profile.roles.some(r => r.id === securityRoleIds.Bells);
    const isInviting = isInvitingFlags.get(item.id);

    const button = (
      <IconButton
        elevated
        tooltip
        aria-label="Send Invite"
        className="hover-only"
        disabled={!hasBellsRole}
        icon={<Envelope />}
        loading={isInviting}
        onClick={() => handleInvite([item])}
      />
    );

    const communication = recentCommunications.get(item.id);

    if (hasBellsRole && communication !== null) {
      let content = <Spinner intent="primary" size={15} />;

      if (communication) {
        const lastSent = Instant.toDate(communication.sent);
        content = <>Last sent at {DateFormats.dateTime(lastSent, militaryTime)}</>;
      }

      return (
        <Tooltip
          content={content}
          onOpening={() => handleShowLastSent(item)}
        >
          {button}
        </Tooltip>
      );
    }

    return button;
  }

  async function handleShowLastSent(practitioner: Practitioner) {
    const communication = recentCommunications.get(practitioner.id);
    const isStaleCommunication = communication && !isCommunicationProcessed(communication);

    // If communication fetch recent & update
    if (communication === undefined) {
      const filterSet: CommunicationFilterSet = {
        ...getCommunicationFilterSet([practitioner.id]),
        status: {
          notIn: ["Failed"],
        },
      };

      const { results } = await apollo.communication.query({
        filters: [filterSet],
        orderBy: {
          field: "Sent",
          direction: "Descending",
        },
        feedOptions: {
          maxItemCount: 1,
        },
      });

      const [response] = results;

      recentCommunications.set(practitioner.id, response ?? null);
      setRecentCommunications(new Map(recentCommunications));
    } else if (isStaleCommunication) { // If communication is stale fetch latest & update
      const updatedCommunication = await apollo.communication.fetchById(communication.id);
      if (isCommunicationProcessed(updatedCommunication)) {
        recentCommunications.set(practitioner.id, updatedCommunication);
        setRecentCommunications(new Map(recentCommunications));
      }
    }
  }

  async function handleMultipleInvites() {
    await handleInvite(Array.from(usersToInvite.values()));
    setUsersToInvite(new Map());
    setAllSelected(false);
  }

  async function handleInvite(practitioners: Practitioner[]) {
    // Set isInvitingFlags
    practitioners.forEach(p => isInvitingFlags.set(p.id, true));
    setIsInvitingFlags(new Map(isInvitingFlags));

    const about = practitioners.map(p => ({
      id: p.id,
      display: p.display,
      resourceType: p.resourceType,
    }));

    const recipients: Recipient[] = practitioners
      .filter(p => !!getUserEmail(p))
      .map(p => {
        const email = getUserEmail(p)!;

        return {
          telecom: {
            system: ContactMethod.Email,
            address: email,
          },
          user: {
            id: p.id,
            display: p.display,
            resourceType: p.resourceType,
          },
        };
      });

    const response = await apollo.communication.create({
      about,
      recipients,
      status: "Preparation",
      payloads: [],
      topic: {
        codings: [knownCodings.communicationTopics.staffInvite],
      },
    });

    // Don't bother saying Resend event if its multiple users
    const isInvitationSent = practitioners.length === 1
      ? await hasCommunications(practitioners[0])
      : false;

    if (tracking) {
      if (isInvitationSent) {
        tracking.track("User Management - Welcome Email Resend");
      } else {
        tracking.track("User Management - Welcome Email Send");
      }
    }

    notification.show({
      message: recipients.length > 1 ? Text.InvitationsSent : Text.InvitationSent,
      intent: "success",
      timeout: 3000,
    });

    // Remove isInviting flags
    setIsInvitingFlags(new Map());

    // Set recent communications
    const newCommunication: Communication = {
      ...response,
      sent: Instant.now(),
    };
    practitioners.forEach(p => recentCommunications.set(p.id, newCommunication));
    setRecentCommunications(new Map(recentCommunications));
  }

  async function hasCommunications(item: Practitioner): Promise<number> {
    return await apollo.communication.queryCount({
      filters: [
        getCommunicationFilterSet([item.id]),
      ],
      abort: abort.signal,
    });
  }

  async function handlePull(item: Practitioner) {
    setPullingUser(item.display);
    try {
      const result = await ehr.pull("Practitioner", item.id, abort.signal);
      if (result.outcome === "Updated") {
        // Refresh item in view
        await store.practitioners.fetch(item.id, { abort: abort.signal });
      }
    } catch (error) {
      handleError(error);
    } finally {
      setPullingUser("");
    }
  }

  function handleCancelResync() {
    setPullingUser("");
    abort.reset();
  }

  function handleViewChange(items: Practitioner[]) {
    debouncer.delay(() => executeViewChange(items));
  }

  function handleBulkUpdateEmails() {
    setShowBulkUpdateEmails(true);
  }

  async function executeViewChange(items: Practitioner[]) {
    if (!analytics) {
      return;
    }

    const notLoaded = items.map(i => i.id).filter(id => !lastSignedIn.current.has(id));

    if (notLoaded.length === 0) {
      return;
    }

    let currentIndex = 0;

    while (currentIndex < notLoaded.length) {
      const fetchForIds = notLoaded.slice(currentIndex, currentIndex + 100);
      await loadLastSignedIn(fetchForIds);
      currentIndex += 100;
    }
  }

  async function loadLastSignedIn(userIds: string[]) {
    const results = await analytics.lastSignedIn({
      data: {
        profiles: userIds,
      },
      abort: abort.signal,
    });

    lastSignedIn.set(map => {
      results.forEach(lastSignedIn => {
        const date = lastSignedIn.lastSignInDate;
        map.set(lastSignedIn.profile, date ? DateTime.fromISO(date, { zone: "UTC" }).toLocal() : null);
      });
      return new Map(map);
    });
  }

  async function getSuperviseesCount(user: Practitioner): Promise<number> {
    setLoadingSuperviseesCount(true);
    try {
      return await apollo.practitioners.queryCount({
        filters: createPractitionerFilters({ activeTenure: true }).map(filter => ({
          ...filter,
          supervisor: { in: [user.id] },
        })),
      });
    } catch (error) {
      handleError(error);
      return 0;
    } finally {
      setLoadingSuperviseesCount(false);
    }
  }

  async function getAmbientListeningUsersCount(): Promise<number> {
    setLoadingAmbientListeningUsersCount(true);
    try {
      return await apollo.practitioners.queryCount({
        filters: createPractitionerFilters({ roles: ["ambient-listening-enabled"] }),
      });
    } catch (error) {
      handleError(error);
      return 0;
    } finally {
      setLoadingAmbientListeningUsersCount(false);
    }
  }

  async function handleExport() {
    setExporting(true);
    try {
      const users = await loadUsers();
      await executeViewChange(feed.items); // Load Last Signed in date
      await exportUsers(users);
    } finally {
      setExporting(false);
    }
  }

  async function loadUsers() {
    while (feed.canLoadMore && !abort.signal.aborted) {
      await feed.loadMore(100, abort.signal);
    }

    return feed.items;
  }

  async function exportUsers(users: Practitioner[]) {
    const data = users.map(user => ({
      "First Name": user.name.given.join(" "),
      "Last Name": user.name.family,
      "Email": getUserEmail(user),
      ...showStaffUsername && { [Text.Username]: user.extensions?.find(e => e.name === "username")?.value },
      ...showStaffHireDate && { [Text.HireDate]: DateFormats.date(LocalDate.toDate(user.tenure.start)) },
      ...showStaffHireDate && { "Terminated Date": DateFormats.date(LocalDate.toDate(user.tenure.end)) },
      ...niamEnabled && { [Text.NiamEnabled]: user.extensions?.find(e => e.name === "niam")?.value },
      [Text.LastSignedIn]: DateFormats.date(lastSignedIn.current.get(user.id)),
      [Text.BellsEnabled]: user.profile.roles.some(r => r.id === securityRoleIds.Bells),
      [Text.Administrator]: user.profile.roles.some(r => r.id === securityRoleIds.Administrative),
      [Text.Supervisor]: user.profile.roles.some(r => r.id === securityRoleIds.Supervisor),
      [`All ${labels.Patients}`]: user.patientPopulationOverride
        ? user.patientPopulationOverride === "All"
        : practicePreference.patientPopulationLevel === "All",
      [Text.CareTeam]: user.patientPopulationOverride
        ? user.patientPopulationOverride === "CareTeam"
        : practicePreference.patientPopulationLevel === "CareTeam",
    }));

    const xlsx = await import("xlsx");
    const sheet = xlsx.utils.json_to_sheet(data);
    const book = xlsx.write({
      SheetNames: ["Users"],
      Sheets: {
        Users: sheet,
      },
    }, { bookType: "xlsx", type: "array" });
    const file = new Blob([book], { type: Constants.exportFileType });
    FileSaver.saveAs(file, "users" + Constants.exportFileExtension);
  }
}

function getCommunicationFilterSet(userIds: string[]): CommunicationFilterSet {
  return {
    about: {
      in: userIds,
    },
    status: {
      matches: "Succeeded",
    },
    topicName: {
      equalTo: knownCodings.communicationTopics.staffInvite.display,
    },
  };
}

function eligibleForInvite(practitioner: Practitioner) {
  return !!getUserEmail(practitioner) && practitioner.profile.roles.some(r => r.id === securityRoleIds.Bells);
}

function isCommunicationProcessed(communication: Communication) {
  return communication.status === "Failed" || communication.status === "Succeeded";
}

function getStatusLabel(status: UserAccessStatus) {
  switch (status) {
    case "Active": return "Active";
    case "Pending": return "Pending";
    case "Expired": return "Terminated / Separated";
  }
}
