import { useEffect, useState } from "react";
import { uniq } from "lodash-es";
import { Button, Classes, Dialog, FormGroup, HTMLTable, ProgressBar, useAbort } from "@remhealth/ui";
import { PractitionerSearchRecord as ExternalPractitioner, PullByIdentifierResult } from "@remhealth/mastodon";
import { Text } from "~/text";
import { useEhr } from "~/services";
import { useErrorHandler } from "~/app";
import { cleanNameForImport } from "./utils";
import { Failures, ProgressContainer, ProgresssInfo, UsersTextArea } from "./importEhrUserDialog.styles";

export interface ImportEhrUserDialogProps {
  isOpen: boolean;
  onImported: (practitioner: ExternalPractitioner, result: PullByIdentifierResult) => Promise<void>;
  onClose: () => void;
}

interface ImportFailure {
  query: string;
  reason: string;
}

const placeholder = "John Smith\nKevin Smith\n... and so on ...";

export const ImportEhrUserDialog = (props: ImportEhrUserDialogProps) => {
  const { isOpen, onClose, onImported } = props;

  const ehr = useEhr();
  const abort = useAbort();
  const handleError = useErrorHandler();

  const [data, setData] = useState("");
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState<number>();
  const [failures, setFailures] = useState<ImportFailure[]>([]);

  useEffect(() => {
    if (isOpen) {
      abort.reset();

      setData("");
      setTotal(undefined);
      setCurrent(0);
      setFailures([]);
    }
  }, [isOpen]);

  const importing = total !== undefined;
  const doneImporting = current === total;
  const successfulCount = total !== undefined ? total - failures.length : 0;

  return (
    <Dialog
      autoFocus
      isOpen={isOpen}
      title="Bulk Import Users"
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        {importing
          ? (
            <>
              <ProgressContainer>
                <ProgressBar intent="success" stripes={!doneImporting} value={current / total} />
                <ProgresssInfo>
                  {doneImporting
                    ? <>Imported <strong>{successfulCount}</strong> user{successfulCount !== 1 ? "s" : ""}.</>
                    : <>Importing <strong>{current}</strong> of <strong>{total}</strong>...</>}
                </ProgresssInfo>
              </ProgressContainer>
              {failures && failures.length > 0 && (
                <>
                  <h4>{failures.length} import failure{failures.length !== 1 ? "s" : ""}</h4>
                  <Failures>
                    <HTMLTable compact stickyHeader>
                      <thead>
                        <tr>
                          <th>Search query</th>
                          <th>Failure reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {failures.map((failure, idx) => (
                          <tr key={idx}>
                            <td>{failure.query}</td>
                            <td>{failure.reason}</td>
                          </tr>
                        ))}
                      </tbody>
                    </HTMLTable>
                  </Failures>
                </>
              )}
            </>
          )
          : (
            <>
              <FormGroup label="Enter names separated by line:" subLabel="Paste in the names of users you wish to import, one name per line.">
                <UsersTextArea fill large placeholder={placeholder} onChange={setData} />
              </FormGroup>
            </>
          )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {importing && current !== total && <Button intent="danger" label="Abort" onClick={handleClose} />}
          {importing && current === total && <Button minimal intent="primary" label="Close" onClick={handleClose} />}
          {!importing && <Button minimal intent="primary" label={Text.Cancel} onClick={handleClose} />}
          {!importing && <Button disabled={!data} intent="primary" label="Import" onClick={handleImport} />}
        </div>
      </div>
    </Dialog>
  );

  function handleClose() {
    abort.reset();
    onClose();
  }

  async function handleImport() {
    const signal = abort.signal;
    const searchQueries = uniq(data.split("\n").map(q => q.trim()).filter(q => !!q));

    setCurrent(0);
    setTotal(searchQueries.length);

    for (const query of searchQueries) {
      if (signal.aborted) {
        break;
      }

      try {
        const name = cleanNameForImport(query);
        const response = await ehr.searchPractitioners(name, { limit: 5 }, signal);
        if (response.results.length === 0) {
          setFailures(f => [...f, { query: name, reason: "No matches found." }]);
        } else if (response.continuationToken) {
          setFailures(f => [...f, { query: name, reason: "Too many matches." }]);
        } else {
          for (const item of response.results) {
            await importEhrUser(item);
          }
        }

        setCurrent(c => c + 1);
      } catch (error) {
        handleError(error);
      }
    }
  }

  async function importEhrUser(item: ExternalPractitioner) {
    const result = await ehr.pull(item, abort.signal);
    await onImported(item, result);
  }
};
