import { useEffect, useState } from "react";
import { FloppyDisk } from "@remhealth/icons";
import { Button, Classes, FormGroup, SelectInput, TextArea } from "@remhealth/ui";
import { useAccessToken } from "@remhealth/host";
import { useErrorHandler } from "@remhealth/core";
import { MesaConfig } from "~/config";
import { AppStatus, type Build, ConfigService } from "./configService";
import { SizedDialog } from "./editBuildDialog.styles";

export interface EditBuildDialogProps {
  config: MesaConfig;
  build?: Build;
  onSaveComplete?: (result: Build) => void;
  onClose: () => void;
}

const statuses = Object.values(AppStatus);

export const EditBuildDialog = (props: EditBuildDialogProps) => {
  const { config, build, onSaveComplete, onClose } = props;

  const token = useAccessToken();
  const handleError = useErrorHandler();

  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState<AppStatus>(build?.status ?? "Usable");
  const [releaseNotes, setReleaseNotes] = useState(build?.releaseNotes);
  const [retirementNotes, setRetirementNotes] = useState(build?.retirementNotes);

  useEffect(() => {
    setStatus(build?.status ?? "Usable");
    setReleaseNotes(build?.releaseNotes);
    setRetirementNotes(build?.retirementNotes);
  }, [build]);

  return (
    <SizedDialog isCloseButtonShown isOpen={!!build} title={`Editing ${build?.display}`} onClose={handleClose}>
      <div className={Classes.DIALOG_BODY}>
        <FormGroup disabled={saving} label="Status">
          <SelectInput<AppStatus>
            disabled={saving}
            filterable={false}
            items={statuses}
            optionRenderer={s => s}
            selectedItem={status}
            onItemSelect={setStatus}
          />
        </FormGroup>
        <FormGroup disabled={saving} label="Release Notes">
          <TextArea disabled={saving} value={releaseNotes} onChange={setReleaseNotes} />
        </FormGroup>
        <FormGroup disabled={saving} label="Retirement Notes">
          <TextArea disabled={saving} value={retirementNotes} onChange={setRetirementNotes} />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon={<FloppyDisk />}
            intent="primary"
            label="Save"
            loading={saving}
            onClick={handleSave}
          />
        </div>
      </div>
    </SizedDialog>
  );

  function handleClose() {
    onClose();
    setStatus("Usable");
    setReleaseNotes(undefined);
    setRetirementNotes(undefined);
  }

  async function handleSave() {
    if (!build) {
      return;
    }

    setSaving(true);

    const service = new ConfigService(config.urls.config);

    try {
      let saved: Build | null = null;

      if (build.status !== status) {
        saved = await service.setBuildStatus(token.accessToken, { buildGuid: build.id, status });
      }

      if (build.releaseNotes !== releaseNotes) {
        saved = await service.setBuildReleaseNotes(token.accessToken, { buildGuid: build.id, notes: releaseNotes ? releaseNotes : null });
      }

      if (build.retirementNotes !== retirementNotes) {
        saved = await service.setBuildRetirementNotes(token.accessToken, { buildGuid: build.id, notes: retirementNotes ? retirementNotes : null });
      }

      if (saved) {
        onSaveComplete?.(saved);
      }
      onClose();
    } catch (error) {
      handleError(error);
    } finally {
      setSaving(false);
    }
  }
};
