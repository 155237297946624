import { type Dispatch, type SetStateAction, useMemo } from "react";
import type { SortField } from "@remhealth/apollo";
import { useSearchParamValue } from "./useSearchParamValue";
import { useSearchParamBoolean } from "./useSearchParamBoolean";

export function useSearchParamSort<T extends string>(sortableFields: T[], defaultValue: SortField<T>): [value: SortField<T>, setValue: Dispatch<SetStateAction<SortField<T>>>] {
  const [fieldValue, setFieldValue] = useSearchParamValue("sort");
  const [descending, setDescending] = useSearchParamBoolean("direction", "desc");

  const sortValue = useMemo<SortField<T>>(() => {
    if (isValueValid(fieldValue)) {
      return {
        field: fieldValue,
        direction: descending ? "Descending" : "Ascending",
      };
    }

    return {
      field: defaultValue.field,
      direction: descending ? "Descending" : defaultValue.direction,
    };
  }, [fieldValue, descending])

  return [sortValue, handleSetValue];

  function handleSetValue(desiredValue: SetStateAction<SortField<T>>) {
    const newSortValue = typeof desiredValue === "function" ? desiredValue(sortValue) : desiredValue;

    if (sortValue.field !== newSortValue.field) {
      setFieldValue(newSortValue.field);
    }

    if (sortValue.direction !== newSortValue.direction) {
      setDescending(newSortValue.direction === "Descending");
    }
  }

  function isValueValid(value: string): value is T {
    return sortableFields.includes(value as T);
  }
}
