import styled, { css } from "styled-components";
import type { Intent } from "@remhealth/ui";

export interface EditorProps {
  $intent: Intent;
  $skin: "minimal" | "soft" | "input";
}

export const Editor = styled.div<EditorProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  transform: translate3d(0,0,0); /** "null transform hack" to fix bug with box-shadow and transitions */
  transition-timing-function: var(--pt-transition-ease);
  transition-duration: 250ms;
  transition-property:
    background-color,
    border-radius,
    border-color,
    box-shadow,
    opacity,
    border-top-left-radius,
    border-top-right-radius,
    border-bottom-left-radius,
    border-bottom-right-radius;

  &.resize-vertical {
    resize: vertical;
  }

  &.resize-horizontal {
    resize: horizontal;
  }

  &.resize-both {
    resize: both;
  }

  /** If nested within another editor */
  .compose && {
    margin: 5px;
  }

  /** Hide blank editor and toolbar if translate editor is present */
  &:has(.translate):has(> .quill > .ql-container > .ql-blank) {
    > .quill > .ql-container,
    > .toolbar {
      display: none;
    }
  }

  /** Hide toolbar when focused on translate editor */
  &:has(.translate.focused) {
    > .toolbar {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.input.background.disabled} !important;
    color: ${props => props.theme.font.disabled};

    h1, h2, h3, h4, h5, h6 {
      color: ${props => props.theme.font.disabled};
    }

    * {
      cursor: not-allowed !important;
    }
  }

  &:not(.disabled) {
    &.compose-skin-soft,
    &.compose-skin-input {
      background-color: ${props => props.theme.input.background.default};
    }

    &.compose-skin-minimal,
    &.compose-skin-soft {
      ${props => props.$intent !== "none" && css`
        background-color: ${props.theme.input.background[props.$intent]};
      `};
    }

    ${props => props.$intent !== "none" && css`
      > .quill > .ql-container > .ql-editor.ql-blank::before {
        color: ${props.theme.font.intents[props.$intent]};
        opacity: 0.6;
      }
    `};
    }

  > .toolbar {
    padding-right: 10px;
  }

  &.compose-skin-input {
    &:not(.disabled) {
      box-shadow: ${props => props.theme.input.border.default};

      &.focused:not(.readonly) {
        box-shadow: ${props => props.theme.input.border.focus};
      }

      ${props => props.$intent !== "none" && css`
        box-shadow: ${props.theme.input.border[props.$intent]};
      `};
    }
  }

  &.compose-skin-minimal {
    > .quill > .ql-container > .ql-editor.ql-blank::before {
      left: 0;
    }
  }

  &.compose-skin-soft,
  &.compose-skin-input {
    > .quill > .ql-container > .ql-editor.ql-blank::before {
      left: 10px;
    }

    > .quill > .ql-container > .ql-editor {
      padding: 8px 10px;
    }

    > .toolbar {
      padding-left: 4px;
    }

    // If toolbar is above editor
    > .toolbar:has(~ .quill) {
      padding-top: 4px;
    }

    // If toolbar is below editor
    > .quill ~ .toolbar {
      padding-bottom: 4px;
    }

    border-radius: var(--pt-border-radius);
  }
`;
