import Delta from "quill-delta";
import type { AttributeMap } from "quill-delta";
import Quill from "quill";
import type { EmitterSource } from "quill/core/emitter";
import matchers from "./matchers";

export interface ContentOptions {
  /**
   * Delta operation attributes [name, value]
   * @example
   * attributes: { bold: true, header: 4, mention: {...} }
   * @example
   * attributes: attr => ({ ...attr, mention: false })
   */
  attributes?: AttributeMap | ((attributes?: AttributeMap) => AttributeMap | undefined);
}

export function applyOptions(delta: Delta, options?: ContentOptions): void {
  if (!options) {
    return;
  }

  if (options.attributes) {
    delta.forEach(op => {
      if (typeof op.insert === "string") {
        if (typeof options.attributes === "function") {
          op.attributes = options.attributes(op.attributes);
        } else {
          op.attributes = {
            ...op.attributes,
            ...options.attributes,
          };
        }
      }
    });
  }
}

export function getHtml(content: Delta): string;
export function getHtml(content: Delta | null): string | null;
export function getHtml(content: Delta | null): string | null {
  if (content === null) {
    return null;
  }

  if (content?.ops && content.ops.length > 0) {
    const editor = createEditor();
    setQuillContents(editor, content);
    return "" + editor.root.innerHTML;
  }

  return "";
}

export function getDelta(html: string): Delta {
  const editor = createEditor();
  return editor.clipboard.convert({ html });
}

export function setQuillContents(quill: Quill, delta: Delta, source?: EmitterSource) {
  const newDelta = new Delta([...delta.ops]);

  // Avoid performance costs: https://github.com/quilljs/quill/issues/1537
  quill.setContents([]);
  quill.setContents(newDelta, source);
}

let editor: Quill | null = null;
function createEditor() {
  if (editor) {
    return editor;
  }

  const container = document.createElement("div");
  container.style.display = "none";

  // Content must be rooted for clipboard to correctly compute contents
  document.body.append(container);

  editor = new Quill(container, {
    modules: {
      clipboard: {
        matchers,
      },
    },
  });

  // Prevents quill's matchText from collapsing multiple spaces
  container.style.whiteSpace = "pre";
  editor.root.style.whiteSpace = "pre";

  return editor;
}

export function isBlankLine(delta: Delta): boolean {
  let isBlankLine = false;

  for (const op of delta.ops) {
    if (op.insert && typeof op.insert === "string") {
      if (op.insert.length > 0 && op.insert !== "\n") {
        return false;
      }

      // Multiple blank lines encountered
      if (isBlankLine) {
        return false;
      }

      isBlankLine = true;
    }
  }

  return isBlankLine;
}

export function getDeltaAddedLength(delta: Delta): number {
  let length = 0;

  delta.forEach(op => {
    if (typeof op.insert === "string") {
      length += op.insert.length;
    }
  });

  return length;
}
