import React, { Fragment, type Ref, type RefObject, forwardRef, isValidElement, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import classNames from "classnames";
import { Classes as CoreClasses, Intent } from "@blueprintjs/core";
import { ChevronDown, ChevronUp } from "@remhealth/icons";
import { type FieldValidation, handleKeyEvent, isControlKey } from "~/utils";
import { useAutomation, useCallbackRef, useStateRef, useUpdateEffect } from "~/hooks";
import { getComponentId } from "./formScope";
import { AutomationInput, type AutomationInputHandle } from "./automationInput";
import { DialInputGroup, UnitCol, UnitInput } from "./dial.styles";
import { ClearButton } from "./common.styles";

export interface DialSlot {
  label: string;
  width: number;
  className?: string;
  placeholder?: string;
  arrowIncrement: number;
  scrollIncrement: number;
  toggle?: boolean;
  defaultValue: number;
  /** Reference to a slot that shifts if this slot wraps around its min/max */
  parent: number | null;
  nowrap?: boolean;
  min: (value: number[] | null) => number;
  max: (value: number[] | null) => number;
  format?: (value: number) => string;
  parse?: (str: string) => number;
  /** @default true */
  numericOnly?: boolean;
  shiftInputOnKeys?: string[];
}

export type DialScheme = (DialSlot | JSX.Element | string)[];

export interface DialProps extends React.AriaAttributes {
  scheme: DialScheme;
  defaultValue?: number[];
  autoFocus?: boolean;
  /** @default true */
  placeholders?: boolean;
  value?: number[] | null;
  id?: string;
  name?: string;
  field?: FieldValidation;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  large?: boolean;
  fill?: boolean;
  intent?: Intent;
  canClearSelection?: boolean;
  rightElement?: JSX.Element;
  parse: (value: string) => number[] | null | undefined;
  format: (value: number[]) => string;
  clamp?: (value: number[]) => number[];
  validate?: (value: number[]) => boolean;
  onChange?: (values: number[] | null) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>, slot: DialSlot, isFocusingSelf: boolean) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>, slot: DialSlot) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>, slot: DialSlot) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>, slot: DialSlot) => void;
}

export interface DialHandle {
  readonly containerElement: HTMLElement | null;
  setValue(value: number[] | null): void;
}

export const Dial = forwardRef((props: DialProps, ref: Ref<DialHandle>) => {
  const {
    scheme,
    defaultValue,
    placeholders = true,
    field,
    name = field?.name,
    value: controlledValue,
    intent = Intent.NONE,
    className,
    autoFocus,
    disabled = false,
    readOnly = false,
    large = false,
    fill = false,
    canClearSelection = false,
    rightElement: controlledRightElement,
    parse,
    format,
    clamp,
    validate,
    onClick,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onKeyUp,
    ...divProps
  } = props;

  const slots = useMemo(() => getSlots(scheme), [scheme]);
  const blankTexts = useMemo(() => getBlankText(scheme), [scheme]);
  const texts = useStateRef<string[]>(blankTexts);
  const prevTexts = useStateRef<string[]>(blankTexts);
  const uncontrolledValue = useStateRef<number[] | null>(defaultValue ?? null);
  const focusedSlot = useStateRef<number | undefined>(undefined);
  const containerElement = useRef<HTMLDivElement>(null);
  const automationInput = useRef<AutomationInputHandle>(null);

  const focusableRefs = useMemo<RefObject<HTMLInputElement>[]>(() => slots.map(() => ({ current: null })), [slots]);
  const empty = useMemo(() => !texts.current.some(t => !!t), [texts]);

  const onInputScroll = useCallbackRef(handleInputScroll);
  const onChangeCallback = useCallbackRef(onChange);

  const { label, id } = useAutomation(props);
  const clearButtonId = getComponentId(id, "clear");

  const desiredValue = controlledValue !== undefined
    ? controlledValue
    : uncontrolledValue.current;
  const value = desiredValue ? clamp?.(desiredValue) ?? desiredValue : null;
  const automationValue = useMemo(() => value ? format(value) : "", [value]);

  useEffect(() => {
    updateTexts(value);
  }, [blankTexts, slots, value?.join(",")]);

  useEffect(() => {
    const unlisteners = focusableRefs.map((ref, index) => {
      const listener = (e: WheelEvent) => onInputScroll(index, e);
      const refCurrent = ref.current;
      refCurrent?.addEventListener("wheel", listener);
      return () => refCurrent?.removeEventListener("wheel", listener);
    });

    return () => {
      unlisteners.forEach(l => l());
    };
  }, [value?.join(","), slots, disabled, readOnly]);

  useUpdateEffect(() => {
    if (controlledValue) {
      uncontrolledValue.set(controlledValue);
    }
  }, [controlledValue?.join(",")]);

  const classes = useMemo(() => classNames(className, {
    [CoreClasses.DISABLED]: disabled,
    [CoreClasses.FILL]: fill,
    [CoreClasses.LARGE]: large,
  }), [disabled, fill, large]);

  const inputClasses = useMemo(() => classNames({
    [CoreClasses.DISABLED]: disabled,
    [CoreClasses.LARGE]: large,
  }), [disabled, large]);

  const onSetValue = useCallbackRef(updateValue);

  useImperativeHandle(ref, () => ({
    get containerElement(): HTMLElement | null {
      return containerElement.current;
    },
    setValue: onSetValue,
  }), []);

  const rightElement = canClearSelection ? (
    <>
      {controlledRightElement}
      <ClearButton
        minimal
        square
        $hidden={!value}
        aria-label={label ? `Clear ${label}` : "Clear"}
        disabled={disabled}
        icon="cross"
        id={clearButtonId}
        onClick={handleClear}
      />
    </>
  ) : controlledRightElement;

  return (
    <DialInputGroup
      ref={containerElement}
      $empty={empty}
      $intent={intent}
      className={classes}
      data-name={name}
      role="group"
      onClick={handleClick}
      {...divProps}
    >
      {renderInputRow()}
      {rightElement}
      <AutomationInput
        ref={automationInput}
        {...props}
        defaultValue={automationValue}
        onChange={handleAutomationInputChange}
      />
    </DialInputGroup>
  );

  function renderInputRow() {
    let pos = 0;

    const items: JSX.Element[] = [];

    for (const item of scheme) {
      if (typeof item === "string") {
        items.push(renderDivider(item));
      } else if (isDialSlot(item)) {
        items.push(renderInput(item, pos));
        pos++;
      } else {
        items.push(item);
      }
    }

    return items.map((item, index) => <Fragment key={index}>{item}</Fragment>);
  }

  function renderDivider(text: string) {
    return <span aria-hidden>{text}</span>;
  }

  function renderInput(slot: DialSlot, pos: number) {
    const text = texts.current[pos] ?? "";

    const hasSomeUnits = texts.current.some(t => t.length > 0);

    const isValid = text.length === 0
      ? focusedSlot.current !== undefined
        ? true
        : !hasSomeUnits
      : isUnitTextValid(value, slot, text);

    const toggle = !!slot.toggle && text.length !== 0;

    return (
      <UnitCol className={classNames(inputClasses, slot.className, "column")}>
        <ChevronUp size={10} />
        <UnitInput
          ref={focusableRefs[pos]}
          $intent={!isValid ? Intent.DANGER : Intent.NONE}
          $toggle={toggle}
          $width={slot.width}
          aria-invalid={!isValid}
          autoComplete="off"
          autoFocus={pos === 0 && autoFocus}
          className={classNames(inputClasses, "slot")}
          disabled={disabled}
          inputMode="numeric"
          maxLength={getMaxLength(value, slot)}
          placeholder={placeholders ? slot.placeholder : undefined}
          readOnly={readOnly}
          role="presentation"
          value={text}
          onBlur={getInputBlurHandler(slot)}
          onChange={getInputChangeHandler(pos)}
          onClick={toggle ? getInputToggleClickHandler(pos) : undefined}
          onFocus={getInputFocusHandler(slot, pos)}
          onKeyDown={getInputKeyDownHandler(slot, pos)}
          onKeyUp={getInputKeyUpHandler(slot, pos)}
        />
        <ChevronDown size={10} />
      </UnitCol>
    );
  }

  function updateTexts(value: number[] | null) {
    const isValid = !value || !validate ? true : validate(value);
    if (isValid) {
      const newTexts = value ? slots.map((slot, pos) => (slot.format ?? defaultFormat)(value[pos])) : blankTexts;
      texts.set(newTexts);
      prevTexts.set(newTexts);
    } else if (blankTexts.length !== texts.current.length) {
      texts.set(blankTexts);
      prevTexts.set(blankTexts);
    }
  }

  function getInputToggleClickHandler(pos: number) {
    return () => {
      shift(pos, value?.[pos] ? slots[pos].min(value) : slots[pos].max(value));
      selectAll(pos);
    };
  }

  function getInputChangeHandler(pos: number) {
    return (e: React.SyntheticEvent<HTMLInputElement>) => {
      const text = e.currentTarget.value;
      const previousText = texts.current[pos] ?? "";

      const newTexts = [...texts.current];
      newTexts[pos] = text;

      // Prefill as long as they didn't backspace
      if (text && text.length >= previousText.length) {
        for (let i = pos; i < slots.length; i++) {
          if (!newTexts[i]) {
            newTexts[i] = (slots[i].format ?? defaultFormat)(slots[i].defaultValue);
          }
        }
      }

      texts.set(newTexts);
    };
  }

  function getInputBlurHandler(slot: DialSlot) {
    return (e: React.FocusEvent<HTMLInputElement>) => {
      const isFocusingSelf = !!e.relatedTarget && focusableRefs.some(ref => {
        return e.relatedTarget === ref.current;
      });
      calculateOnBlur(isFocusingSelf);
      onBlur?.(e, slot, isFocusingSelf);
    };
  }

  function calculateOnBlur(isFocusingSelf: boolean) {
    // Delay announcing new time if user is still focusing on other fields within this control
    if (!isFocusingSelf) {
      focusedSlot.set(undefined);

      const newValue = calculateFromTexts();
      const hasNewValue = !isSameValue(value, newValue);
      if (hasNewValue) {
        updateValue(newValue);
      }
    }
  }

  function calculateFromTexts(): number[] | null {
    let value: number[] | null = getCurrentOrDefault();

    for (let i = 0; i < slots.length; i++) {
      // Allow defaulting if field was previously empty already,
      // otherwise we assume user could be emptying the field
      const allowDefault = !prevTexts.current[i];
      value = setValueIfValid(value, texts.current[i] ?? "", slots[i], i, allowDefault);
    }

    return value;
  }

  function getInputFocusHandler(slot: DialSlot, pos: number) {
    return (e: React.FocusEvent<HTMLInputElement>) => {
      e.currentTarget.select();
      focusedSlot.set(pos);
      onFocus?.(e, slot);
    };
  }

  function handleInputScroll(pos: number, e: WheelEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (e.deltaY < 0) {
      shift(pos, slots[pos].scrollIncrement);
    } else {
      shift(pos, -slots[pos].scrollIncrement);
    }

    selectAll(pos);

    return false;
  }

  function shiftInput(pos: number, previous: boolean, selectAll: boolean): boolean {
    const nextPos = previous ? pos - 1 : pos + 1;
    if (focusableRefs[nextPos]) {
      const input = focusableRefs[nextPos].current;
      if (input) {
        const caret = previous ? input.value.length : 0;
        input.focus();
        input.setSelectionRange(selectAll ? 0 : caret, selectAll ? input.value.length : caret);
        return true;
      }
    }
    return false;
  }

  function selectAll(pos: number) {
    setTimeout(() => {
      const input = focusableRefs[pos].current;
      if (input) {
        input.focus();
        input.select();
      }
    }, 0);
  }

  function getInputKeyDownHandler(slot: DialSlot, pos: number) {
    const numericOnly = slot.numericOnly ?? true;
    return (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (isControlKey(e)) {
        handleKeyEvent(e, "ArrowUp", () => handleArrowUp(pos), true);
        handleKeyEvent(e, "ArrowDown", () => handleArrowDown(pos), true);
        handleKeyEvent(e, "ArrowRight", () => shiftFromArrowKeys(e, pos, false, true), false);

        if (pos !== 0) {
          handleKeyEvent(e, "ArrowLeft", () => shiftFromArrowKeys(e, pos, true, true), false);
        }

        // Backspacing empty field, try to apply it to previous field
        if (e.currentTarget.value.length === 0) {
          handleKeyEvent(e, "Backspace", () => shiftInput(pos, true, false), false);
        }
      } else if (numericOnly && !/[\d.-]/.test(e.key)) {
        // Assume numeric only if no custom parser
        e.preventDefault();
      } else {
        const shiftInputOnKeys = slot.shiftInputOnKeys ?? [];
        if (shiftInputOnKeys.includes(e.key) && shiftInput(pos, false, true)) {
          e.preventDefault();
        } else {
          // Auto-shift to next input
          shiftInputAfterTyping(e, value, slot, pos);
        }
      }

      onKeyDown?.(e, slot);
    };
  }

  function getInputKeyUpHandler(slot: DialSlot, pos: number) {
    return (e: React.KeyboardEvent<HTMLInputElement>) => {
      // Auto-shift to next input
      shiftInputAfterTyping(e, value, slot, pos);

      onKeyUp?.(e, slot);
    };
  }

  function handleArrowDown(pos: number) {
    shift(pos, -1);
    selectAll(pos);
  }

  function handleArrowUp(pos: number) {
    shift(pos, 1);
    selectAll(pos);
  }

  function shiftUnit(values: number[], pos: number, amount: number): number[] {
    const oldValue = values[pos];
    const incrementDelta = oldValue % amount;
    const increment = incrementDelta === 0
      ? amount
      : amount > 0 ? amount - incrementDelta : -incrementDelta;
    const newValue = oldValue + increment;
    const max = slots[pos].max(values);
    const min = slots[pos].min(values);

    const willWrap = newValue > max || newValue < min;

    if (willWrap && slots[pos].nowrap) {
      return values;
    }

    // Unit will wrap, so also shift immediate parent
    const parent = slots[pos].parent;
    if (willWrap && parent !== null) {
      values = shiftUnit(values, parent, amount > 0 ? 1 : -1);
    }

    // Recalculate min/max now that we've shifted parent
    const nextMax = slots[pos].max(values);
    const nextMin = slots[pos].min(values);

    if (newValue > max) {
      values[pos] = nextMin + (nextMin % amount);
    } else if (newValue < min) {
      values[pos] = nextMax - (nextMax % amount);
    } else {
      values[pos] = newValue;
    }

    return values;
  }

  function getCurrentOrDefault(): number[] {
    if (value) {
      return [...value];
    }

    const defaultValue = slots.map(slot => slot.defaultValue);

    // Try parsing current text values
    const parsedValue: number[] = [];
    for (let index = 0; index < slots.length; index++) {
      const text = texts.current[index];
      const slot = slots[index];

      if (!text) {
        parsedValue.push(slot.defaultValue);
      } else {
        const unit = slot.parse?.(text) ?? Number.parseInt(text, 10);

        // Bail out if invalid unit
        if (!isUnitValid(null, slot, unit)) {
          return defaultValue;
        }

        parsedValue.push(unit);
      }
    }

    return parsedValue;
  }

  function shift(pos: number, amount: number) {
    if (disabled || readOnly) {
      return;
    }

    const values = calculateFromTexts() ?? getCurrentOrDefault();
    const newValues = shiftUnit(values, pos, amount);

    updateValue(newValues);
  }

  function updateValue(desiredValue: number[] | null, dispatchEvent = true) {
    const newValue = desiredValue && clamp ? clamp(desiredValue) : desiredValue;

    if (!isSameValue(newValue, value) || !isSameValue(newValue, desiredValue)) {
      uncontrolledValue.set(newValue);
      updateTexts(newValue);
      handleChange(newValue, dispatchEvent);
    }
  }

  function handleChange(value: number[] | null, dispatchEvent: boolean) {
    onChangeCallback(value);

    if (dispatchEvent) {
      automationInput.current?.setValue(value ? format(value) : "");
    }
  }

  function handleAutomationInputChange(value: string) {
    if (!value) {
      updateValue(null, false);
    } else {
      const newValue = parse(value);
      if (newValue !== undefined) {
        updateValue(newValue, false);
      }
    }
  }

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    onClick?.(event);

    const target = event.target;
    if (event.defaultPrevented || !(target instanceof Node)) {
      return;
    }

    // User clicked on a focusable, so no auto-focus needed
    const clickedFocusable = focusableRefs.find(ref => ref.current && (ref.current === target || ref.current.contains(target)));
    if (clickedFocusable) {
      return;
    }

    // Try to find neighboring slot to auto-focus
    const nextSlot = findNeighboringParent(event.currentTarget, target);
    if (!nextSlot) {
      return;
    }

    const nextFocusable = focusableRefs.find(ref => ref.current && (ref.current === nextSlot || nextSlot.contains(ref.current)));
    if (nextFocusable?.current) {
      nextFocusable.current.focus();
    }
  }

  function handleClear() {
    updateValue(null);
  }

  function shiftFromArrowKeys(e: React.KeyboardEvent<HTMLInputElement>, pos: number, previous: boolean, preventDefault: boolean) {
    let { selectionStart, selectionEnd } = e.currentTarget;
    const inputLength = e.currentTarget.value.length;

    if (selectionStart !== null && selectionEnd !== null && selectionStart > selectionEnd) {
      selectionStart = selectionEnd;
      selectionEnd = e.currentTarget.selectionStart;
    }

    // Don't change focus if text is selected, unless its the entire value
    if (selectionStart !== selectionEnd) {
      if (selectionStart !== 0 || selectionEnd !== inputLength) {
        return;
      }
    }

    // Don't go forward unless cursor is at end
    if (!previous && selectionEnd !== inputLength) {
      return;
    }

    // Don't go back unless cursor is at start
    if (previous && selectionStart !== 0) {
      return;
    }

    if (preventDefault) {
      e.preventDefault();
    }

    shiftInput(pos, previous, false);
  }

  function shiftInputAfterTyping(e: React.KeyboardEvent<HTMLInputElement>, value: number[] | null, slot: DialSlot, pos: number) {
    // Auto-shift to next input
    if (isControlKey(e)) {
      return;
    }

    const text = e.currentTarget.value;
    if (text.length === 0) {
      return;
    }

    const unit = slot.parse?.(text) ?? Number.parseInt(text, 10);
    if (!isUnitValid(value, slot, unit)) {
      return;
    }

    const maxLength = getMaxLength(value, slot);
    const endOfInput = e.currentTarget.selectionStart === text.length && e.currentTarget.selectionEnd === text.length;
    if (endOfInput) {
      // Shift if reached max length
      if (text.length === maxLength) {
        shiftInput(pos, false, true);
      } else {
        // Shift if next digit is impossible due to max value
        const max = slot.max(value);
        const lowestPossibleNext = unit * 10;
        if (lowestPossibleNext > max) {
          shiftInput(pos, false, true);
        }
      }
    }
  }
});

function getMaxLength(value: number[] | null, slot: DialSlot) {
  return (slot.format ?? defaultFormat)(slot.max(value)).length;
}

function getSlots(scheme: DialScheme): DialSlot[] {
  return scheme.filter(isDialSlot);
}

export function isDialSlot(item: DialScheme[number]): item is DialSlot {
  if (typeof item === "string") {
    return false;
  }

  if (isValidElement(item)) {
    return false;
  }

  return true;
}

function getBlankText(scheme: DialScheme): string[] {
  return getSlots(scheme).map(() => "");
}

function defaultFormat(value: number): string {
  return value.toString();
}

function isUnitTextValid(value: number[] | null, slot: DialSlot, text: string) {
  if (text.length === 0) {
    return false;
  }
  return isUnitValid(value, slot, slot.parse?.(text) ?? Number.parseInt(text, 10));
}

function isUnitValid(value: number[] | null, slot: DialSlot, unit?: number) {
  return unit != null && !Number.isNaN(unit) && slot.min(value) <= unit && unit <= slot.max(value);
}

function setValueIfValid(value: number[] | null, text: string, slot: DialSlot, pos: number, allowDefault: boolean): number[] | null {
  if (value === null) {
    return null;
  }

  const unit = text.length > 0 ? slot.parse?.(text) ?? Number.parseInt(text, 10) : allowDefault ? slot.defaultValue : null;

  if (unit === null) {
    return null;
  }

  if (!isUnitValid(value, slot, unit)) {
    return value;
  }

  const newValue = [...value];
  newValue[pos] = unit;
  return newValue;
}

function isSameValue(left: number[] | null, right: number[] | null): boolean {
  if (left === null || right === null) {
    return left === right;
  }

  return left.length === right.length && left.every((value, index) => value === right[index]);
}

function findNeighboringParent(parent: Element, target: Node): Element | undefined {
  const children = parent.children;
  for (let i = 0; i < children.length; i++) {
    const slotEl = children[i];
    if (slotEl !== target && !slotEl.contains(target)) {
      continue;
    }

    return children[i + 1] || children[i - 1];
  }

  return undefined;
}
