import { type Dispatch, type SetStateAction, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage, useStateRef, useUpdateEffect } from "@remhealth/ui";
import { useSearchParamsRef } from "./useSearchParamsRef";

type IsValidValue<T extends string> = (value: string) => value is T;

export interface SearchParamListOptions<T extends string> {
  name: string;
  validValues: T[] | IsValidValue<T>;
  defaultValues?: T[];
  storageKey?: string;
}

export function useSearchParamList<T extends string>(name: string, validValues: T[] | IsValidValue<T>, storageKey?: string): [values: T[], setValues: Dispatch<SetStateAction<T[]>>];
export function useSearchParamList<T extends string>(options: SearchParamListOptions<T>): [values: T[], setValues: Dispatch<SetStateAction<T[]>>];
export function useSearchParamList<T extends string>(arg1: string | SearchParamListOptions<T>, arg2?: T[] | IsValidValue<T>): [values: T[], setValues: Dispatch<SetStateAction<T[]>>] {
  const name = typeof arg1 === "string" ? arg1 : arg1.name;
  const validValues = typeof arg1 === "string" ? arg2! : arg1.validValues;
  const { defaultValues, storageKey } = typeof arg1 === "string" ? {} : arg1;

  const location = useLocation();
  const navigate = useNavigate();
  const localStorage = useLocalStorage();
  const searchParams = useSearchParamsRef();

  const initialValues = useMemo(getInitialValues, [name]);

  const allowGoBack = useStateRef(searchParams.current.size === 0);

  const path = useStateRef(() => location.pathname);
  const values = useStateRef(() => cleanValues(searchParams.current.getAll(name)));
  const currentValues = cleanValues(searchParams.current.getAll(name));

  useEffect(() => {
    if (path.current !== location.pathname) {
      path.set(location.pathname);
      allowGoBack.set(false);
    }

    if (values.current.length === 0 && initialValues && initialValues.length > 0) {
      setValues(initialValues, true);
    }
  }, [name, path]);

  // Handle when browser changes query (e.g. user pressed Back)
  useUpdateEffect(() => {
    if (!areValuesEqual(values.current, currentValues)) {
      handleSetValues(currentValues);
    }
  }, [currentValues.join("|")]);

  return [values.current, handleSetValues];

  function handleSetValues(desiredValues: SetStateAction<T[]>) {
    const newValues = typeof desiredValues === "function" ? desiredValues(values.current) : desiredValues;
    setValues(newValues);
  }

  function setValues(newValues: T[], forceReplace = false) {
    const currentValues = cleanValues(searchParams.current.getAll(name));

    if (!areValuesEqual(currentValues, newValues)) {
      if (newValues.length === 0 && canGoBack()) {
        // Return to no search param
        navigate(-1);
      } else {
        searchParams.set(searchParams => {
          if (newValues.length > 0) {
            searchParams.delete(name);

            for (const value of newValues) {
              searchParams.append(name, value);
            }

            return new URLSearchParams(searchParams);
          }

          if (searchParams.has(name)) {
            searchParams.delete(name);
            return new URLSearchParams(searchParams);
          }

          return searchParams;
        }, { replace: forceReplace || searchParams.current.size > 0 });

        allowGoBack.set(!forceReplace);
      }
    }

    if (storageKey) {
      localStorage.setItem(storageKey, JSON.stringify(newValues), { slidingExpiration: "30d" });
    }

    values.set(newValues);
  }

  function getInitialValues(): T[] | undefined {
    if (storageKey) {
      const storageItem = localStorage.getItem(storageKey);
      if (storageItem) {
        return JSON.parse(storageItem) as T[];
      }
    }

    return defaultValues;
  }

  function canGoBack() {
    if (allowGoBack.current) {
      return searchParams.current.size > 0 && searchParams.current.size === searchParams.current.getAll(name).length;
    }

    return false;
  }

  function cleanValues(values: string[]): T[] {
    return values.flatMap(v => isValueValid(v) ? v : []);
  }

  function isValueValid(value: string): value is T {
    if (typeof validValues === "function") {
      return validValues(value);
    }

    return validValues.includes(value as T);
  }
}

function areValuesEqual<T>(left: T[], right: T[]) {
  if (left.length !== right.length) {
    return false;
  }

  if (left.some(l => !right.includes(l))) {
    return false;
  }

  if (right.some(l => !left.includes(l))) {
    return false;
  }

  return true;
}
