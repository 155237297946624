import React, { useState } from "react";
import classnames from "classnames";
import { Classes, MenuDivider } from "@blueprintjs/core";
import { Filter, FilterKeep, FilterRemove } from "@remhealth/icons";
import { ListGrouping } from "../utils";
import { Button, IconButton } from "./button";
import { Checkbox } from "./toggles";
import { Placement } from "./popover";
import { MenuItem } from "./menuItem";
import { ButtonRow, Container, Content, FilterPopover } from "./groupColumnFilter.styles";

export interface GroupColumnFilterProps<T> {
  "aria-label": string;
  groups: ListGrouping<T>[];
  selectedItems: ReadonlyArray<T>;
  labelRenderer: (item: T) => string;
  titleRenderer: (key: string | null) => JSX.Element;
  placement?: Placement;
  onChange: (selectedItems: T[]) => void;
}

export function GroupColumnFilter<T>(props: GroupColumnFilterProps<T>) {
  const {
    "aria-label": ariaLabel,
    selectedItems: controlledSelectedItems,
    groups,
    labelRenderer,
    titleRenderer,
    onChange,
    placement = "bottom-start",
  } = props;

  const [pendingSelections, setPendingSelections] = useState<ReadonlyArray<T>>();

  const selectedItems = pendingSelections ?? controlledSelectedItems;
  const hasFiltered = selectedItems.length > 0;
  const hasChanges = selectedItems.length !== controlledSelectedItems.length
    || selectedItems.some(i => !controlledSelectedItems.includes(i))
    || controlledSelectedItems.some(i => !selectedItems.includes(i));

  const content = (
    <Container data-filter-menu>
      <Content>
        {groups.map((groupItem, index) => (
          <React.Fragment key={index}>
            <MenuDivider title={titleRenderer(groupItem.key)} />
            {groupItem.items.map((item, index) => (
              <MenuItem
                key={index}
                shouldDismissPopover={false}
                text={(
                  <Checkbox
                    alignIndicator="right"
                    checked={selectedItems.includes(item)}
                    data-item={labelRenderer(item)}
                    label={labelRenderer(item)}
                    onChange={e => handleFilterChange(e, item)}
                  />
                )}
              />
            ))}
          </React.Fragment>
        ))}
      </Content>
      {(hasFiltered || hasChanges) && (
        <ButtonRow>
          {hasFiltered && (
            <Button
              data-filter-clear
              minimal
              className={classnames(Classes.POPOVER_DISMISS, "clear")}
              icon={<FilterRemove />}
              intent="primary"
              label="Clear"
              onClick={handleClear}
            />
          )}
          {hasChanges && (
            <Button
              data-filter-button
              minimal
              className={classnames(Classes.POPOVER_DISMISS, "filter")}
              icon={<FilterKeep />}
              intent="primary"
              label="Apply"
            />
          )}
        </ButtonRow>
      )}
    </Container>
  );

  return (
    <FilterPopover content={content} placement={placement} onClosed={handleClosed} onOpened={handleOpened}>
      <IconButton
        data-column-filter
        minimal
        tooltip
        aria-label={ariaLabel}
        icon={hasFiltered ? <FilterKeep /> : <Filter />}
        intent={hasFiltered ? "primary" : "none"}
      />
    </FilterPopover>
  );

  function handleFilterChange(event: React.FormEvent<HTMLInputElement>, item: T): void {
    const { checked } = event.currentTarget;
    setPendingSelections(pendingSelections => {
      const selectedItems = pendingSelections ?? controlledSelectedItems;
      return checked ? [...selectedItems, item] : selectedItems.filter(r => r !== item);
    });
  }

  function handleClear() {
    setPendingSelections([]);
  }

  function handleOpened() {
    setPendingSelections(controlledSelectedItems);
  }

  function handleClosed() {
    if (hasChanges) {
      onChange([...selectedItems]);
    }
  }
}
