import { type Dispatch, type SetStateAction, useEffect } from "react";
import { useDebouncer, useStateRef } from "@remhealth/ui";
import { useSearchParamValue } from "./useSearchParamValue";

export interface SearchParamTextOptions {
  /**
   * Delay before query updates after searchText changes.
   * @default 300
   */
  debounceMs?: number;

  /**
   * Name of the parameter in the search URL to use for queries.
   * @default "query"
   */
  name?: string;
}

export interface SearchParamTextHook {
  searchText: string;
  query: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  setQuery: Dispatch<SetStateAction<string>>;
}

export function useSearchParamText(options?: SearchParamTextOptions): SearchParamTextHook {
  const { debounceMs = 300, name = "query" } = options ?? {};

  const [query, setQuery] = useSearchParamValue(name);

  const searchText = useStateRef(query);
  const lastKnownQuery = useStateRef(query);
  const debouncer = useDebouncer(debounceMs);

  // Handle when browser changes query (e.g. user pressed Back)
  useEffect(() => {
    if (lastKnownQuery.current !== query) {
      lastKnownQuery.set(query);
      setSearchText(query);
    }
  }, [name, query]);

  return {
    searchText: searchText.current,
    query,
    setSearchText: handleSetSearchText,
    setQuery: handleSetQuery,
  };

  function handleSetQuery(value: SetStateAction<string>) {
    const newValue = typeof value === "function" ? value(lastKnownQuery.current) : value;
    lastKnownQuery.set(newValue);

    // Immediately update both query and search
    setQuery(newValue);
    setSearchText(newValue);
  }

  function handleSetSearchText(value: SetStateAction<string>) {
    const newValue = typeof value === "function" ? value(searchText.current) : value;

    // Immediately update search text
    setSearchText(newValue);

    // Debounce query update
    debouncer.delay(() => setQuery(newValue));
  }

  function setSearchText(value: string) {
    searchText.set(value);
  }
}
